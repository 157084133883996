.quote-card {
    /* background-color: #FFFFFF; */
    border-radius: 50px;
    /* padding: 50px 1rem 0; */
    box-sizing: border-box;
    width: 100%;
    max-width: 1000px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  svg {
    &.open-quote {
      flex: 1 0 50px;
      width: 25px;
      height: 25px;
      padding: 5px;
      @media (min-width:600px) {
        padding: 0;
      }
      }
    &.close-quote {
      width: 25px;
      height: 25px;
      flex: 1 0 50px;
      align-self: flex-end;
      width: 25px;
      height: 25px;
      padding: 5px;
      @media (min-width:600px) {
        padding: 0;
      }
    }
    path {
      fill: var(--dark-teal);
    }
  }
  @media (min-width: 600px) {
    // padding: 50px 2.5rem 0;
    svg {
      &.open-quote {
        // left: 2px;
      }
      &.close-quote {
        // right: 2px;
      }
    }
  }
}
.fade-container {
  padding:0;
  width: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  @media (min-width:600px) {    
    padding: 0.75rem 2rem;
  }
  .quote-slide-wrapper {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    img {
      width:100%;
      height: auto;
      // margin-bottom: 1.5rem;
      border: 1px solid var(--dark-teal);
      padding: 10px;
      border-radius: 27px;
      @media (min-width:600px) {
        width: 60%;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 1.25rem;
      text-align: center;
      width:100%;
      background: #fff;
      border-radius: 27px;
      color: var(--dark-teal);
      display: flex;
      @media (min-width:600px) {        
        width: 550px;
        margin-left: -250px;
        padding: 20px;
      }
      span {
        font-style:italic;
        padding: 20px;
        display: inline-block;
      }
    }
  }
  @media (min-width: 600px) {
    padding: 0.75rem;
    .quote-slide-wrapper {
      flex-direction: row;
      img {
        margin-bottom: 0;
        margin-right: 1.2rem;
      }
      p {
        text-align: left;
      }
    }
  }
  @media (min-width: 900px) {
    .quote-slide-wrapper {
      p {
        font-size: 1.2rem;
      }
    }
  }
}
.button-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  height: 60px;
  max-width: 300px;
  width: 100%;
  button {
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.75rem;
    padding: 0;
    border: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    transform: scale(1);
    width: 100%;
    &[data-active-quote="true"] {
      color: var(--dark-teal);
    }
    &:hover {
      transform: scale(1.4);
    }
    svg {
      width: 1.75rem;
      height: 1.75rem;
      position: relative;
      top: 2px;
    }
  }
}