.fp-gradient-container {
  background: linear-gradient(
    180deg,
    rgba(241, 255, 252, 0) 0%,
    var(--keppel) 129.58%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fp-banner {
  width: 100%;
  padding: 0 15px;
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 2rem;
  #videoRight {
    background: var(--keppel);
  }
  @media (min-width: 600px) {
    padding-top: 3rem;
  }
}
.fp-banner h1 {
  margin-top: 0;
  span.color {
    color: var(--keppel);
  }
}
@media (min-width: 600px) {
  .fp-banner h1 {
    font-size: 3.5rem;
  }
}
.fp-banner-container {
  display: flex;
  flex-direction: column;
}
.fp-banner-image-wrapper {
  border: 1px solid #062175;
  border-radius: 50px;
  padding: 10px;
  width: 100%;
  max-width: 520px;
}
.fp-banner-img {
  border-radius: 40px;
  height: 400px;
  object-fit: cover;
}
.fp-banner-container-bottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 20px 30px;
}
.fp-banner-container-bottom p {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.2;
  max-width: 540px;
}
.question-options {
  margin-top: 17px;
  display: flex;
  background: #f1f2f2;
}
ul.fp-steps {
  margin: 0;
  padding: 0 0 0 1.5rem;
}
ul.fp-steps > li {
  margin-bottom: 1rem;
}
.banner-text {
  width: 100%;
  max-width: 650px;
}
.banner-text p {
  font-size: 1.125rem;
}
.fp-text {
  text-align: center;
}
.fp-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: 8px;
}
.fp-testimonial-section {
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  svg.double-quote {
    position: absolute;
    width: 30px;
    height: 30px;
    &.open-quote {
      top: 2px;
      left: 0px;
    }
    &.close-quote {
      top: auto;
      left: auto;
      right: 0px;
      bottom: 0px;
    }
    path {
      fill: var(--topaz);
    }
  }
}
.fp-testimonial-section .blockquote-wrapper {
  background-color: #ffffff;
  border-radius: 50px;
  padding: 50px 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 850px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
}
.fp-testimonial-section blockquote.quote {
  position: relative;
}
.fp-testimonial-section blockquote.EN {
  border-top: 1px solid #fec671;
  border-bottom: 1px solid #fec671;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
  margin: 0 35px;
}

.fp-testimonial-section blockquote p {
  font-size: 1.375rem;
  line-height: 1.4;
  margin: 0;
  text-align: center;
}
.fp-testimonial-img {
  width: 106px;
  height: 106px;
  margin-right: 20px;
}
.fp-icon-text {
  font-weight: 600;
}
.pb-180 {
  padding-bottom: 180px;
}
@media (min-width: 600px) {
  .banner-text {
    padding-right: 2rem;
    flex: 0 0 50%;
  }
  .fp-banner-container,
  .fp-banner-container-bottom {
    flex-direction: row;
  }
  .fp-banner-container-bottom {
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    padding: 65px 0 50px;
  }
  .fp-banner-container-bottom > div {
    margin: 15px;
  }
  .fp-banner-bottom {
    background-image: linear-gradient(
      90deg,
      var(--keppel) 74.58%,
      var(--keppel) 94.58%
    );
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position-y: 70%;
    display: flex;
    justify-content: center;
  }
  .fp-testimonial-section {
    svg.double-quote {
      &.open-quote {
        left: -32px;
      }
      &.close-quote {
        right: -32px;
      }
    }
  }
  .fp-testimonial-section blockquote.EN {
    flex-direction: row;
    padding: 10px 0;
  }
  .fp-testimonial-section blockquote.EN p {
    text-align: left;
  }
  .section-header h2 {
    font-size: 3.5rem;
  }
}
@media (min-width: 900px) {
  .fp-banner {
    margin-bottom: 50px;
  }
  .banner-text {
    padding-right: 3rem;
  }

  .frp-healthcare-right-for-you {
    position: relative;
  }

  .fp-banner-container-bottom {
    position: relative;
  }
}
@media (min-width: 1200px) {
  .banner-text {
    padding-right: 5rem;
    flex: 0 0 60%;
  }
}
