.ReactModal__Overlay.ReactModal__Overlay--after-open {
        max-width: 80%;
        margin: 0 auto;
        height:auto;
    }
.ReactModal__Content.ReactModal__Content--after-open {
    border: 10px solid var(--lt-purple) !important;
    border-radius: 50px !important;
    background: var(--lt-pink);
    padding:0 !important;
    .md-modal-accordion-details {
        padding: 1rem;
    }
    .MuiAccordionSummary-contentGutters {
        flex-direction:column;
    }
    .MuiAccordionSummary-expandIconWrapper svg {
        width: 11rem;
        height: 5rem;
    }
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
        align-items:flex-start;
    }
    
    a {
        color: var(--deep-pink);
        font-weight: 400;
    }
    .close {
        background: var(--deep-red);
        border: none;
        padding: 0.4rem 0.8rem;
        border-radius: 50%;
        color: white;
        font-weight: 700;
        float:right;
        margin: 1rem;
    }
    .display-h2 {
        color: var(--deep-pink);
    }
    .headerUnderline {
        width: 300px;
        margin: 0 auto;
    }
    .headImg {
        width: 90%;
        margin: 0 auto;
        margin-top: 1rem;
        @media (min-width:350px) {
            width:50%;
        }
    }
    .modalnoFlex {
        margin-top: 2rem;
    }
    .modalFlex {
        display:flex;
        flex-wrap:nowrap;
        flex-direction:column;
        @media (min-width:885px) {
            flex-direction:row;
        }
        justify-content: space-evenly;
        .flexCard {
            display:flex;
            flex: 1 1 100%;
            margin-top: 2rem;
            align-items: flex-start;
            @media (min-width:350px) {
                flex: 1;
            }
            .icon {
                width: 54px;
                flex: 0 0 54px;
                margin: 0 1rem;
            }
        }
    }

}

