.h-container {
&.section-specialist {
    background: linear-gradient(0deg, rgba(32, 148, 173, 0.5) -49%, rgb(255, 255, 255) 100%);
    h2.display-h2 {
      color:var(--dark-teal);
      margin-top: 3rem;
    }
    img.specialist {
      width: 500px;
      margin: 2rem 0;
      border-radius: 27px;
      border: 1px solid var(--dark-teal);
      padding: 20px;
      height: auto;
    }
    h4 {
      text-align: center;
      max-width: 700px;
      font-weight: 600;
      color: var(--dark-teal);
      font-size: 30px;
      margin: 2rem 0;
    }
    
  }
  a {
    text-decoration: none;
  }
  .btn-specialist {
    background: var(--dark-teal);
    color: #fff;
    border-radius: 27px;
    border-color: transparent;
    padding: 0.5rem 1rem;
    font-size: 1.5rem;
    cursor:pointer;
  }
}