.underline {
    text-decoration: underline;
}
.audio-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    audio {
        margin-top: 1rem;
    }
}
.btn-view {
    width: auto;
    padding: 0 2rem;
    border-radius: 28px;
    border: 0;
    height: 54px;
    margin-left: 1rem;
    margin-top: 1rem;
    color: var(--dark-charcoal);
    background-color: rgba(239, 239, 239, 1);
    &:hover {
        text-decoration: underline;
        background-color: rgba(255, 255, 255, 1);
    }
}
.transcript-modal {
    .modal-title {
      border-bottom: 4px solid var(--cg-blue);
      padding-top: 2rem;
      margin-bottom: 2rem;
      h3 {
        margin-bottom: 1.25rem;
      }
    }
    .modal-content {
        p {
            line-height: 1.75;
        }
    }
    .modal-footer {
      justify-content: flex-end;
      .btn-close {
        width: auto;
        border-radius: 28px;
        padding: 0 2rem;
        color: var(--white);
        margin: 0;
        margin-right: 1.5rem;
      }
    }
  }