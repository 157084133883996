

// modal styles
.dsmes-modal {
  &.modal-theme-yellow {
    .MuiBackdrop-root {
      background-color: rgb(217 28 92 / 18%);
    }
    .MuiDialog-paper {
      background-color: var(--lt-pink);
      max-width:800px;
    }
    .modal-header {
      border-color: var(--gold);
    }
    .modal-footer {
      button {
        background-color: var(--deep-red);
        &:hover, &:active {
          background-color: var(--deep-red);
        }
      }
      
    }
  }
}
  @media (min-width: 600px) {
.dsmes-modal .modal-title {
    padding: 60px 60px 0 60px;
}
  }
.modalFlex {
  a {
  color: var(--deep-pink);
  font-weight: 700;
  &.org-button.xyz {
    color: var(--deep-pink);
    font-weight:700;
  }
}
}
.close {
  background: var(--deep-red);
  border: none;
  padding: 0.4rem 0.8rem;
  border-radius: 50%;
  color: white;
  font-weight: 700;
  float:right;
  margin: 1rem;
}
.display-h2 {
  color: var(--deep-pink);
}
.headerUnderline {
  width: 300px;
  margin: 0 auto;
}
.headImg {
  width: 90%;
  margin: 0 auto;
  margin-top: 1rem;
  @media (min-width:350px) {
      width:50%;
  }
}
.modalnoFlex {
  margin-top: 2rem;
}
.meditationButton {
  border-radius: 27px;
  background: var(--deep-pink);
  color: white;
  text-align: center;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  a {
    text-decoration:none;
  }
}
.modalFlex {
  display:flex;
  flex-wrap:nowrap;
  flex-direction:column;
  @media (min-width:885px) {
      flex-direction:row;
  }
  justify-content: space-evenly;
  .flexCard {
      display:flex;
      flex: 1 1 100%;
      margin-top: 2rem;
      align-items: flex-start;
      @media (min-width:350px) {
          flex: 1;
      }
      .icon {
          width: 54px;
          flex: 0 0 54px;
          margin: 0 1rem;
      }
  }
}