.healthy-accordion-section-hr {
  border: 5px solid rgba(90, 160, 245, 1);
  margin-top: 160px;
  position: relative;
  width: 100%;
}

.healthy-accordion-sections-hr {
  border: 1px solid rgba(90, 160, 245, 1);
}

.healthy-accordion-section {
  background: linear-gradient(
    180deg,
    #ffff,
    rgba(185, 239, 255, 0.554),
    #f6ffbe
  );
  padding-bottom: 50px;
}

.healthy-accordion-section-block {
  padding: 0px;
  width: 62%;
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 300px 1fr;
}

.healthy-accordion-section-block-img {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  position: relative;
  margin-top: -150px;
}

.healthy-accordion-section-block-text {
  margin-top: -150px;
  margin-left: 50px;
}

.healthy-accordion-section-block-text h1 {
  font-weight: 400;
  margin: 0;
  width: 60%;
  font-family: 'Lato', sans-serif;
}

.healthy-accordion-section-block-text p {
  margin-top: 30px;
  font-family: 'Lato', sans-serif;
}

.healthy-accordion-section-accordions {
  border-radius: 15px;
  box-shadow: 0px 4px 14px 2px rgba(0, 0, 0, 0.25);
  padding: 16px;
  background-color: #ffffff;
}

.healthy-accordion-section-cooking-section {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 3rem;
  max-width: 100%;
  font: 500 100%/1.5 system-ui;
}

.healthy-accordion-section-accordions p {
  margin-top: 0px;
}

.healthy-accordion-section-cooking-section-img {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  width: 100%;
  height: auto;
}

.healthy-accordion-section-cooking-section-text {
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 400;
}

.healthy-accordion-section-shopping-section {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 3rem;
  max-width: 100%;
  font: 500 100%/1.5 system-ui;
}

.healthy-accordion-section-shopping-section-img {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  width: 100%;
  height: auto;
}

.healthy-accordion-section-shopping-section-text {
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 400;
}

.healthy-accordion-section-eating-section {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 3rem;
  max-width: 100%;
  font: 500 100%/1.5 system-ui;
}

.healthy-accordion-section-eating-section-img {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  width: 100%;
  height: auto;
}

.healthy-accordion-section-eating-section-text {
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 400;
}

.healthy-accordion-section-help-section {
  font-size: 18px;
  line-height: 21.6px;
  font-weight: 400;
}

@media (min-width: 3164px) {
  .healthy-accordion-section-block-text h1 {
    width: 30%;
  }
}

@media (max-width: 3163px) and (min-width: 2133px) {
  .healthy-accordion-section-block-text h1 {
    width: 39%;
  }
}

@media (max-width: 1595px) {
  .healthy-accordion-section-block-text h1 {
    width: 60%;
  }

  .healthy-accordion-section-block {
    width: 78%;
  }
}

@media (max-width: 1268px) {
  .healthy-accordion-section-block-text h1 {
    width: 80%;
  }
}

@media (max-width: 1098px) {
  .healthy-accordion-section-block-text h1 {
    width: 98%;
  }

  .healthy-accordion-section-block-text {
    margin-left: 22px;
  }

  .healthy-accordion-section-cooking-section {
    display: block;
  }

  .healthy-accordion-section-cooking-section-img {
    width: 90%;
    text-align: center;
  }

  .healthy-accordion-section-shopping-section {
    display: block;
  }

  .healthy-accordion-section-shopping-section-img {
    width: 90%;
    text-align: center;
  }

  .healthy-accordion-section-eating-section {
    display: block;
  }

  .healthy-accordion-section-eating-section-img {
    width: 90%;
    text-align: center;
  }
}

@media (max-width: 911px) {
  .healthy-accordion-section-cooking-section {
    display: grid;
    gap: 1rem;
    text-align: left;
  }

  .healthy-accordion-section-shopping-section {
    display: grid;
    gap: 1rem;
    text-align: left;
  }

  .healthy-accordion-section-eating-section {
    display: grid;
    gap: 1rem;
    text-align: left;
  }

  .healthy-accordion-section-block {
    display: flex;
    flex-direction: column;
  }

  .healthy-accordion-section-block-text {
    margin-top: 10px;
    margin-left: 0px;
  }

  .healthy-accordion-section-block-text h1 {
    width: 100%;
  }
}

@media (max-width: 689px) {
  .healthy-accordion-section-cooking-section {
    display: block;
    text-align: left;
  }

  .healthy-accordion-section-shopping-section {
    display: block;
    text-align: left;
  }

  .healthy-accordion-section-eating-section {
    display: block;
    text-align: left;
  }

  .healthy-accordion-section-eating-section span ul li {
    margin-bottom: 10px;
  }
}
