#videoRight {
    position: absolute;
    right: 0;
    // top: 200px;
    height: 600px;
    width: 175px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    z-index: 10;
    border-left: 1px solid #eee;
    background: var(--teal);
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    a {
        text-decoration: none;
        cursor: pointer;
    }
    h3 {
        color: #fff;
        font-size: 26px;
        font-weight: 700;
        text-align: center;
        cursor:pointer;
        a, a:visited, a:active {
            text-decoration:none;
            color: #fff;
        }
    }
    p {
        font-size: 20px;
        color: #fff;
        margin: 0.5rem;
        font-weight: 700;
        line-height: 22px;
    }
    .MuiSvgIcon-fontSizeMedium {
        display:none;
    }
}