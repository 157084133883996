.footer-container {
  background-color: #333333;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 1rem;
}

@media (max-width: 921px) {
  .footer-container {
    grid-template-columns: 1fr;
  }
}

.footer-column {
  padding: 1rem;
  color: var(--white);
}

.information-section {
  margin-top: -17px;
}
.footer-title {
  color: var(--white);
  font-weight: 700;
  font-size: 1.125rem;
}
.footer-link {
  text-decoration: none;
  color: var(--white);
  display: flex;
  align-items: center;
}

.footer-column > p {
  margin-top: 15px;
}

.footer-socialmeadia {
  display: flex;
  align-items: center;
}

.footer-link .social {
  margin: 5px;
  font-size: 30px;
}
