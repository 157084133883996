.healthy-eating-cooking-section {
  background-image: linear-gradient(
    180deg,
    #ffff,
    rgba(185, 239, 255, 0.554),
    #f6ffbe
  );
  padding-bottom: 50px;
  padding-top: 2rem;
  @media (min-width: 600px) {
    padding-top: 3rem;
  }
  .he-banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 2rem;
    width: 100%;
    #videoRight {
      background: #778522;
    }
  }
  .he-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1100px;
    // width: 100%;
    .img-wrapper {
      max-width: 520px;
    }
  }
  .he-banner-text {
    .display-h1 span {
      color: #a6b92f;
    }
    ul {
      padding-left: 1.5rem;
      & > li {
        margin-bottom: 1rem;
      }
    }
  }
  img-wrapper {
    border: 1px solid #000000;
    border-radius: 50px;
    padding: 10px;
    width: 100%;
    img {
      border-radius: 40px;
      width: 100%;
    }
  }
  @media (min-width: 600px) {
    .he-banner {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;
    }
    .he-banner-image {
      display: flex;
      justify-content: flex-end;
    }
    .he-banner-text {
      margin-right: 1rem;
      max-width: 520px;
      flex: 1 1 50%;
      h1.display-h1 {
        margin-bottom: calc(1.325rem + 0.9vw);
        font-size: 3.5rem;
      }
      p {
        line-height: 1.6;
      }
    }
  }
  @media (min-width: 900px) {
    .he-banner-container {
      margin-bottom: 3rem;
    }
  }
  @media (min-width: 1200px) {
    .he-banner-container {
      margin-bottom: 5rem;
    }
  }
}

.healthy-eating-cooking-section-banner-img {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  margin-right: -70px;
}

.healthy-eating-cooking-section-banner-texts p {
  text-align: justify;
  width: 63%;
  font-size: 1.1rem;
  font-family: "Lato", sans-serif;
}

.healthy-eating-cooking-section-banner-texts ul li {
  margin-bottom: 15px;
  width: 55%;
  font-size: 1.1rem;
  font-family: "Lato", sans-serif;
}
.he-insight-section {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 100px;
  overflow: hidden;
}
.he-insight-section .blockquote-wrapper {
  background-color: #ffffff;
  border-radius: 50px;
  padding: 40px 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 850px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
}
.he-insight-section blockquote {
  position: relative;
  border-top: 1px solid rgba(6, 33, 117, 0.31);
  border-bottom: 1px solid rgba(6, 33, 117, 0.31);
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0px;
  margin: 0 35px;
}
.he-insight-section blockquote h2 {
  font-size: 1.375rem;
  margin-bottom: 0;
  font-family: inherit;
}
.he-insight-section blockquote p {
  margin-bottom: 0;
  font-size: 1.375rem;
  line-height: 1.4;
}
@media (min-width: 600px) {
  .he-insight-section blockquote {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .he-insight-section blockquote h2,
  .he-insight-section blockquote p {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .he-insight-section {
    position: relative;
    overflow: visible;
  }
}
.modal-header.mtPortion {
  background-position: center 0px;
}
.modal-header.mth1 {
  background-position: -200px;
}
.healthy-eating-cooking-section-footer {
  padding: 0px;
  width: 62%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 500px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.healthy-eating-cooking-section-footer-text p {
  text-align: left;
  width: 70%;
  font-size: 1.1rem;
  margin-top: 20px;
  line-height: 28px;
  font-family: "Lato", sans-serif;
}

.healthy-eating-cooking-section-footer-img {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  margin-right: -70px;
}

.healthy-eating-cooking-section-footer-line {
  border: 5px solid rgba(90, 160, 245, 0.3);
  margin-top: -200px;
  position: absolute;
  width: 99.5%;
  z-index: -99;
}

@media (max-width: 2560px) and (min-width: 2000px) {
  .healthy-eating-cooking-section-footer {
    width: 59%;
  }
}

@media (max-width: 1440px) {
  .healthy-eating-cooking-section-footer {
    width: 66%;
  }
}

@media (max-width: 1397px) {
  .healthy-eating-cooking-section-footer {
    grid-template-columns: 1fr 300px;
  }

  .healthy-eating-cooking-section-footer-line {
    margin-top: -93px;
  }

  .healthy-eating-cooking-section-footer-text p {
    width: 99%;
    line-height: 23px;
  }
}

@media (max-width: 1024px) {
  .healthy-eating-cooking-section-footer {
    width: 70%;
  }
  .healthy-eating-cooking-section-footer-text p {
    width: 90%;
    font-size: 1.1rem;
    line-height: 25px;
  }

  .healthy-eating-cooking-section-footer-line {
    margin-top: -93px;
  }

  .healthy-eating-cooking-section-footer-text p {
    width: 94%;
    line-height: 18px;
  }
}

@media (max-width: 886px) {
  .healthy-eating-cooking-section-footer-line {
    margin-top: -143px;
  }

  .healthy-eating-cooking-section-footer {
    padding: 0px;
    width: 60%;
    display: block;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  .healthy-eating-cooking-section-footer-img {
    width: 98%;
    margin-right: 0px;
  }
}

@media (max-width: 425px) {
  .healthy-eating-cooking-section-footer {
    width: 80%;
  }

  .healthy-eating-cooking-section-footer-text p {
    width: 100%;
    font-size: 1rem;
  }
}
