.style-guide-main {
    margin: 0 auto;
    width: 100%;
    max-width: 960px;
    padding: 0 1rem;
}
.style-guide-main code {
    display: inline-block;
    background-color: #e0e0e0;
    font-family: monospace, monospace;
    font-size: 0.875rem;
}
.style-guide-main li>code { margin: 0 1rem; }
.style-guide-main .fw-300 { font-weight: 300 !important;}
.style-guide-main .fw-400 { font-weight: 400 !important;}
.style-guide-main .fw-700 { font-weight: 700 !important;}
.style-guide-main .fw-900 { font-weight: 900 !important;}
.style-guide-main .fst-italic { font-style: italic !important;}
.style-guide-main .mb-0 {
    margin-bottom: 0 !important;
}
