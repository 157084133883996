.he-tips-section {
  width: 100%;
  padding: 2rem 1rem;
  display: flex;
  justify-content: center;
}
.he-tips-flex-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  .tip-button-wrapper {
    flex: 0 0 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  button {
    &.scale {
      background-position: center 1px;
      background-size: 146%;
      padding: 0;
      width: 270px;
      height: 400px;
      border: 0;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transform: scale(1);
      transition: transform 0.2s ease-in-out;
    }
  &.tip {
    padding: 0;
    width: 270px;
    height: 400px;
    background-size: cover;
    background-position: center;
    border: 0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
  }
    h2 {
      height: 90px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      color: var(--white);
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 0.75rem;
      font-size: 1.375rem;
      text-decoration: underline;
      text-underline-offset: 0.15em;
      text-decoration-color: rgba(255, 255, 255, 0);
      transition: text-decoration-color 0.2s ease-in-out;
    }
    &:hover {
      transform: scale(1.025);
      h2 {
        text-decoration-color: rgba(255, 255, 255, 1);
      }
    }
  }
}
@media (min-width: 600px) {
  .he-tips-section {
    padding-top: 4rem;
  }
  .he-tips-flex-wrapper {
    justify-content: space-evenly;
    .tip-button-wrapper {
      flex: 0 0 40%;
    }
  }
}
@media (min-width: 900px) {
  .he-tips-section {
    margin-bottom: 30px;
  }
  .he-tips-flex-wrapper {
    justify-content: space-evenly;
    .tip-button-wrapper {
      flex: 0 0 30%;
    }
  }
}
@media (min-width: 1200px) {
  .he-tips-flex-wrapper {
    justify-content: center;
    .tip-button-wrapper {
      flex: 0 0 25%;
    }
  }
}
.flip-card-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  .bfy-title {
    background-color: var(--deep-green);
    color: var(--white);
    text-align: center;
    border-radius: 1rem;
    padding: 0.75rem;
    margin: 0;
    font-weight: 700;
  }
  .avoid-title {
    background-color:var(--upsdell-red);
    color: var(--white);
    text-align: center;
    border-radius: 1rem;
    padding: 0.75rem;
    margin: 0;
    font-weight: 700;
  }
  .card {
    perspective: 40rem;
    border-radius: 1rem;
    .card-body {
      transform-style: preserve-3d;
      transition: 0.7s transform;
    }
    &:hover, &:focus {
      .card-body {
        transform: rotateY(-180deg);
      }
    }
    .card-front, .card-back {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .card-back {
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      transform: rotateY(-180deg); 
    }
    .bfy, .avoid {
      font-weight: 700;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 1rem;
      text-align: center;
    }
    .bfy {
      background-color: var(--deep-green);
      color: var(--white);
    }
    .avoid {
      background-color: var(--upsdell-red);
      color: var(--white);
    }
    .flip-icon {
      position: absolute;
      top: auto;
      left: auto;
      bottom: 0.5rem;
      right: 0.5rem;
    }
    .icon-avoid, .icon-bfy {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      border-radius: 50%;
    }
    .icon-avoid {
      color: var(--upsdell-red);
      background-color: var(--white);
    }
    .icon-bfy {
      color: var(--deep-green);
      background-color: var(--white);
    }
  }
  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr auto 1fr;
    grid-auto-flow: row;
    .bfy-title {
      grid-row: 1;
      grid-column: 1 / span 3;
    }
    .avoid-title {
      grid-row: 3;
      grid-column: 1 / span 3;
    }
  }
}
.flip-card-grid-layout-v2 {
  display: grid;
  gap: 0.25rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: row;
  margin-bottom: 2rem;
  .card {
    perspective: 40rem;
    border-radius: 1rem;
    border: 0;
    background-color: transparent;
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
    &.bfy {
      .card-body {
        transform: rotateY(-180deg);
      }
    }
    .card-body {
      transform-style: preserve-3d;
      transition: 0.7s transform;
    }
    .card-front, .card-back {
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }
    .card-back {
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      transform: rotateY(-180deg); 
    }
    .card-img-wrapper {
      position: relative;
    }
    .flip-icon {
      position: absolute;
      top: auto;
      left: auto;
      bottom: 0.5rem;
      right: 0.5rem;
    }
    .icon-avoid, .icon-bfy {
      position: absolute;
      top: 0.5rem;
      left: 0.5rem;
      border-radius: 50%;
    }
    .icon-avoid {
      color: var(--upsdell-red);
      background-color: var(--white);
    }
    .icon-bfy {
      color: var(--deep-green);
      background-color: var(--white);
    }
    .front-card-caption,
    .back-card-caption {
      margin: 0.25rem 0;
      border-radius: 10px;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.2;
      padding: 0.25rem;
      height: 3rem;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }
    .front-card-caption {
      background-color: var(--upsdell-red);
      color: var(--white);
    }
    .back-card-caption {
      background-color: var(--deep-green);
      color: var(--white);
    }
  }
  @media (min-width: 750px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
}
.nutrition-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  .nutrition-facts {
    grid-row-start: 1;
  }
  .card {
    background-color: var(--banana);
    padding: 0.75rem;
    border-radius: 10px;
    p {
      margin-bottom: 0;
    }
    ul {
      padding-left: 1.25rem;
    }
  }
  @media (min-width: 750px) {
    grid-template-columns: 35% 35% 1fr;
    .nutrition-facts {
      grid-column: 2 / span 2;
      grid-row: 1 / span 3;
    }
  }
}
.bfy-goals {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;
  .card {
    margin: 0.375rem;
    background-color: var(--banana);
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      margin-bottom: 0;
      font-size: 1rem;
    }
  }
}
.plate-method-tips {
  display: flex;
  flex-wrap: wrap;
  .img-wrapper {
    flex: 0 0 auto;
    max-width: 275px;
  }
  ul {
    flex: 1 1 40%;
    padding-left: 2rem;
  }
}
.mtPortion {
    background-position: center 0px;
  }
