.first-flash-card-grid {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
  margin: 0 auto;
  width: 100%;
}

.first-flash-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  position: relative;
  background: none;
  padding: 0;
  border: none;
  font-family: 'Avenir', 'Lato', sans-serif;
  outline: inherit;
  border-radius: 1.25rem;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
  background-color: var(--plum);
  color: var(--white);
  cursor: pointer;
  transform: perspective(1000px) rotateY(var(--rotate-y, 0))
    translateY(var(--translate-y, 0));
  transform-style: preserve-3d;
  transition: 150ms;
}

.first-flash-card:hover {
  --translate-y: -2px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
}

.first-flash-card.flip {
  --rotate-y: 180deg;
}

.first-flash-card .first-flash-card-front,
.first-flash-card .first-flash-card-back {
  position: absolute;
  padding: 1rem;
  backface-visibility: hidden;
}

.first-flash-card .first-flash-card-back {
  transform: rotateY(180deg);
}
