.reducing-risk {
  background-image: linear-gradient(
    180deg,
    rgba(241, 255, 252, 0) 0%,
    var(--keppel) 89.58%
  );
  padding-bottom: 50px;
  padding-top: 2rem;
  @media (min-width: 600px) {
    padding-top: 3rem;
  }
}

.reducing-risk-banner {
  padding: 0px;
  width: 62%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin-top: 20px;
  #videoRight {
    background: var(--keppel);
  }
}

.reducing-risk-banner-text-section {
  margin-top: 35px;
  .display-h1 span {
    color: var(--keppel);
  }
  @media (min-width: 600px) {
    font-size: 3.5rem;
  }
}

.reducing-risk-banner-img {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  margin-left: 10px;
  max-width: 550px;
}

.reducing-risk-banner-text {
  text-align: start;
  width: 80%;
}
.reducing-risk-footer-section {
  padding: 0px;
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 20px;
  margin-bottom: 50px;
  align-items: center;
}

.reducing-risk-footer-image {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  margin-left: 10px;
  width: 480px;
}

.reducing-risk-footer-text {
  margin-top: 10px;
  width: 50%;
  font-size: 1.5rem;
  font-family: "Lato", sans-serif;
  p {
    font-size: 18px;
    text-align: left;
    padding: 1rem;
    margin-bottom: 0;
  }
  img {
    float: left;
    height: 200px;
  }
  button {
    width: 280px;
    height: 56px;
    border-radius: 27px;
    background: var(--keppel);
    color: white;
    border: none;
    margin: 0 auto;
  }
  .btnLink {
    display: block;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.reducing-risk-hash-img {
  margin-top: -72px;
  margin-left: 77px;
}

.reducing-risk-footer-line {
  border: 5px solid #eafffa;
  margin-top: -119px;
  position: absolute;
  width: 99.5%;
  z-index: -99;
}

.reducing-risk-testimonial-section {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 100px;
  overflow: hidden;
  .blockquote-wrapper {
    background-color: #ffffff;
    border-radius: 50px;
    padding: 50px 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 850px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 5;
  }
  blockquote.quote {
    position: relative;
    border-top: 1px solid var(--keppel);
    border-bottom: 1px solid var(--keppel);
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 30px;
    margin: 0 35px;
  }
  .reducing-risk-testimonial-img {
    width: 106px;
    height: 106px;
    margin-right: 16px;
    margin-left: 16px;
  }
  blockquote p {
    font-size: 1.375rem;
    line-height: 1.4;
    margin: 0;
    text-align: center;
  }
  svg.double-quote {
    position: absolute;
    width: 30px;
    height: 30px;
    &.open-quote {
      top: 2px;
      left: 0px;
    }
    &.close-quote {
      top: auto;
      left: auto;
      right: 0px;
      bottom: 0px;
    }
    path {
      fill: var(--keppel);
    }
  }
}
.rr-suggestions-section {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
}
.rr-suggestions-flex-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  .tip-button-wrapper {
    flex: 0 0 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
  }
  button.tip {
    padding: 0;
    width: 270px;
    height: 400px;
    background-size: cover;
    background-position: center;
    border: 0;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    h2 {
      height: 90px;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.75);
      color: var(--white);
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 0.75rem;
      font-size: 1.375rem;
      text-decoration: underline;
      text-underline-offset: 0.15em;
      text-decoration-color: rgba(255, 255, 255, 0);
      transition: text-decoration-color 0.2s ease-in-out;
    }
    &:hover {
      transform: scale(1.025);
      h2 {
        text-decoration-color: rgba(255, 255, 255, 1);
      }
    }
  }
}
.rr-box-wrapper {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: center;
  // .reducing-risk-box-section ul, .reducing-risk-box-section p{
  //   font-size: 1.1rem;
  //   font-family: 'Lato', sans-serif;
  //   line-height: 1.125;
  //   margin-top: 0;
  // }

  .reducing-risk-box-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 960px;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 50px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    p,
    ul {
      font-size: 1.25rem;
      width: 100%;
      max-width: 720px;
    }
    li {
      margin-bottom: 1rem;
    }
  }
}
@media (min-width: 600px) {
  .reducing-risk-testimonial-section {
    blockquote.quote {
      flex-direction: row;
      padding: 10px 0;
    }
    blockquote p {
      text-align: left;
      margin-right: 0.75rem;
      span.strong {
        text-align: right;
        display: block;
      }
    }
    svg.double-quote {
      &.open-quote {
        left: -32px;
      }
      &.close-quote {
        right: -32px;
      }
    }
  }
  .rr-suggestions-flex-wrapper {
    justify-content: space-evenly;
    .tip-button-wrapper {
      flex: 0 0 40%;
    }
  }
}
@media (min-width: 900px) {
  .rr-suggestions-flex-wrapper {
    justify-content: space-evenly;
    .tip-button-wrapper {
      flex: 0 0 30%;
    }
  }
}
@media (min-width: 1200px) {
  .rr-suggestions-flex-wrapper {
    justify-content: center;
    .tip-button-wrapper {
      flex: 0 0 25%;
    }
  }
}
@media (max-width: 900px) {
  .reducing-risk-banner {
    padding: 0px;
    width: 68%;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  .reducing-risk-banner-text-section {
    margin-top: 5px;
    text-align: center;
  }

  .reducing-risk-banner-text {
    width: 100%;
    text-align: center;
  }

  .reducing-risk-footer-section {
    margin-top: 10px;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
  }

  .reducing-risk-footer-text {
    width: 100%;
  }

  .reducing-risk-footer-image {
    width: 300px;
  }
}

@media (max-width: 600px) {
  .reducing-risk-hash-img {
    display: none;
  }
}
