.finding-right-provider-section {
  width: 100%;
  max-width: 800px;
  margin: -140px 0 0;
  padding: 1rem;
  background-color: #ffffff;
  margin-bottom: 100px;
  border-radius: 25px;
  box-shadow: 0px 6px 33px rgba(0, 0, 0, 0.25);
}
.finding-right-provider-section .MuiPaper-root {
  color: #000000;
  background-color: #ffffff;
  box-shadow: none;
}
.finding-right-provider-section .MuiPaper-root::before {
  display: none;
}
.finding-right-provider-section .MuiAccordionSummary-root .MuiSvgIcon-root {
  transform: scale(1.5);
}
.finding-right-provider-section h3.accordion-header {
  font-size: 1.375rem;
  font-weight: 400;
  line-height: 1.4;
  margin: 6px 0;
}
.finding-right-provider-section .frp-img-wrapper {
  border: 1px solid #062175;
  border-radius: 50px;
  padding: 8px;
}
.finding-right-provider-section .frp-img-wrapper > img {
  border-radius: 42px;
  width: 100%;
  max-width: 325px;
  height: auto;
  display: block;
}
.finding-right-provider-section p {
  font-size: 1.125rem;
  line-height: 1.3;
  font-family: "Lato", sans-serif;
  font-weight: 400;
}
hr.finding-right-provider-hr {
  border: 1px solid #f1d11c;
}
.finding-right-provider-insurance-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.finding-right-provider-insurance-points ul {
  list-style: none;
  padding: 0;
}
.finding-right-provider-insurance-points li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
}
.finding-right-provider-insurance-points li img {
  margin: 0 20px;
}
.finding-right-provider-recommendations-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.finding-right-provider-recommendations-question-section {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}
.finding-right-provider-recommendations-question-section ul {
  padding: 0;
}
.finding-right-provider-recommendations-question-section ul > li {
  /* display: flex; */
  align-items: center;
  font-size: 1.125rem;
  font-weight: 400;
  margin: 0 0 1.5em;
}
.finding-right-provider-recommendations-question-section ul > li p {
  margin: 0;
}
.finding-right-provider-recommendations-question-section ul > li::before {
  /* content: ''; */
  display: block;
  width: 18px;
  height: 14px;
  border: 2px solid #000000;
  border-radius: 7px;
  margin-right: 12px;
  flex: 0 0 auto;
}
@media (min-width: 600px) {
  .finding-right-provider-insurance-section {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .finding-right-provider-insurance-section .img-block,
  .finding-right-provider-recommendations-section .img-block {
    flex: 0 0 auto;
    margin-right: 40px;
  }
  .finding-right-provider-insurance-section p:first-child,
  .finding-right-provider-recommendations-section p:first-child {
    margin-top: 0;
  }
  .finding-right-provider-recommendations-section {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .finding-right-provider-recommendations-question-section {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .finding-right-provider-recommendations-question-section .img-block {
    flex: 0 0 250px;
    margin-left: 20px;
  }
}
@media (min-width: 900px) {
  .finding-right-provider-section {
    width: auto;
    max-width: 800px;
    margin: -140px auto 100px;
    position: relative;
  }
}
@media (min-width: 1200px) {
}
