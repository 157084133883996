/*@import "~@fontsource/lato/index.css";
@import "~@fontsource/lato/300.css";
@import "~@fontsource/lato/700.css";
@import "~@fontsource/lato/900.css";*/
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@400&display=swap");
:root {
  --pomegranate: #f33336; //rgba(243, 51, 54, 1)
  --pastel-red: #ff6b6b; //rgba(255, 107, 107, 1)
  --topaz: #fec671; //rgba(254, 198, 113, 1)
  --banana: #fed834; // rgba(254, 216, 52, 1)
  --pale-yellow: #f6ffbe; // rgba(246, 255, 190, 1)
  --white: #ffffff; // rgba(255, 255, 255, 1)
  --blue-diamond: #c2f4ff; // rgba(194, 244, 255, 1)
  --cerulean: #02829e; // rgba(2, 130, 158, 1)
  --grey-green: #555762; // rgba(85, 87, 98, 1)
  --black: #000000; // rgba(0, 0, 0, 1)
  --cg-blue: #007da3; // rgba(0, 125, 163, 1)
  --azure: #f1fffc; // rgba(241, 255, 252, 1)
  --sea-green: #24cca8; // rgba(36, 204, 168, 1)
  //--keppel:       #44B6A0;    // rgba(68, 182, 160, 1)
  --keppel: #2b6f61;
  --deep-green: #066547; // rgba(6, 101, 71, 1)
  --pink: #eac9db; // rgba(234, 201, 219, 1)
  --maroon: #b3407f; // rgba(179, 64, 127, 1)
  --plum: #89094f; // rgba(137, 9, 79, 1)
  --upsdell-red: #a82424; // rgba(168, 36, 36, 1)
  --lt-yellow: #fbffe4; // rgba(251, 255, 228, 1)
  --gold: #e48912; // rgba(228, 137, 18, 1)
  --lt-cyan: #e1fff9; // rgba(225, 255, 249, 1)
  --catalina-blue: #062175; // rgba(6, 33, 117, 1)
  // --lt-pink:      #FFF4FA;    // rgba(255, 244, 250, 1)
  --patricks-blue: #18337b; // rgba(24, 51, 123, 1)
  --lapis-lazuli: #285fa6; // rgba(40, 95, 166, 1)
  --bud-green: #7cbf4d; // rgba(124, 191, 77, 1)
  --med-yellow: #f0af0c; // rgba(240, 175, 12, 1)
  --dark-charcoal: #333333; // rgba(51, 51, 51, 1)
  --gamboge: #fe9901; // rgba(254, 153, 1, 1);
  --deep-red: #d91c5c;
  --deep-pink: #9d1f63;
  --lt-pink: #f8ecf3;
  --purple: #91278e;
  --lt-purple: #ecdbec;
  --melon: #fa9ca8;
  --dark-teal: #095667;
  --teal: #2094ad;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
}
.App > div:nth-child(2) {
  padding-top: 3rem;
}
.main-banner {
  justify-content: flex-end;
  width: 62%;

  [class*="-banner-img"] {
    width: 100%;
  }
}
.MuiPaper-root::before {
  display: none;
}
.md-banner-bottom {
  background-size: contain;
  width: 100%;
}
.App > div > [class*="-bottom-banner"] {
  width: 100%;
  background-size: 1;
  > div {
    width: 75%;
  }
}
.css-1c35hjw-MuiPaper-root-MuiAccordion-root::before {
  display: none;
}
.MuiPaper-root {
  h3 {
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}

a {
  color: inherit;
  font-weight: bold;
}
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
input,
button,
textarea,
select {
  font: inherit;
}
button,
a {
  cursor: pointer;
}
.css-1c35hjw-MuiPaper-root-MuiAccordion-root::before {
  display: none;
  background-color: none;
}
p,
li,
button,
input,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: 1.125rem; /* 18px */
  font-weight: 400;
  line-height: 1.4;
}
.u,
.underline {
  text-decoration: underline;
}
.wsnw {
  white-space: nowrap;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.75rem;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
em,
i,
.italic {
  font-style: italic;
}
b,
strong,
.strong,
.bold {
  font-weight: 700;
}

h1,
.h1 {
  font-size: calc(1.425rem + 2.1vw);
  font-family: "Bebas Neue", "sans-serif";
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 3rem;
  }
}

h2,
.h2 {
  font-size: calc(1.375rem + 1.5vw);
  font-family: "Bebas Neue", "sans-serif";
  text-align: center;
}

@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2.5rem;
  }
}
h3,
.h3 {
  font-size: calc(1.325rem + 0.9vw);
}

@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 2rem;
  }
}
h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}
.display-h1 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 400;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-h1 {
    font-size: 4rem;
  }
}

.display-h2 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 400;
  line-height: 1.2;
}

.SupportTopics {
  padding-top: 3rem;
  background: var(--dark-teal);
  h2 {
    color: #fff;
  }
}
@media (min-width: 1200px) {
  .display-h2 {
    font-size: 3.5rem;
  }
}

@import "/src/styles/modal";
@import "/src/styles/color-themes";
@media print {
  .learning-guide-list {
    .quiz-results-option {
      display: flex;
      .quiz-results-label {
        font-size: 1.5rem;
        height: 45px;
        display: flex;
        align-items: center;
        margin-left: 1rem;
        margin-bottom: 0;
      }
    }
  }
}

.SupportTopics {
  margin-bottom: 1.75rem;
  text-align: center;
}

.hideExternal .org-button.xyz {
  display: none;
}
