.monitoring-diabetes-page {
  padding-top: 2rem;
  @media (min-width: 600px) {
    padding-top: 3rem;
  }
  .gradient-bg-container {
    background: linear-gradient(
      180deg,
      rgba(210, 247, 255, 0) 0%,
      #c2f4ff 63.54%,
      #02829e 100%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    #videoRight {
      background: #02829e;
    }
  }
  .md-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 2rem;
    width: 100%;
    .display-h1 span {
      color: #02829e;
      @media (min-width: 600px) {
        font-size: 3.5rem;
      }
    }
  }
  .md-banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1100px;
    // width: 100%;
    .img-wrapper {
      max-width: 500px;
    }
  }
  .img-wrapper {
    border: 1px solid #000000;
    border-radius: 50px;
    padding: 10px;
    width: 100%;
    img {
      border-radius: 40px;
      width: 100%;
    }
  }
  .md-testimonial-section {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 100px;
    overflow: hidden;
    .blockquote-wrapper {
      background-color: #ffffff;
      border-radius: 50px;
      padding: 50px 0;
      box-sizing: border-box;
      width: 100%;
      max-width: 850px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 5;
    }
    blockquote.quote {
      position: relative;
      border-top: 1px solid var(--cerulean);
      border-bottom: 1px solid var(--cerulean);
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 30px;
      margin: 0 35px;
    }
    .md-testimonial-img {
      width: 106px;
      height: 106px;
      margin-right: 16px;
      margin-left: 16px;
    }
    blockquote p {
      font-size: 1.375rem;
      line-height: 1.4;
      margin: 0;
      text-align: center;
    }
    svg.double-quote {
      position: absolute;
      width: 30px;
      height: 30px;
      &.open-quote {
        top: 2px;
        left: 0px;
      }
      &.close-quote {
        top: auto;
        left: auto;
        right: 0px;
        bottom: 0px;
      }
      path {
        fill: var(--cerulean);
      }
    }
  }
  .md-tips-section {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }
  .md-tips-flex-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    .tip-button-wrapper {
      flex: 0 0 100%;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
    }
    button.tip {
      padding: 0;
      width: 270px;
      height: 400px;
      background-size: cover;
      background-position: center;
      border: 0;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transform: scale(1);
      transition: transform 0.2s ease-in-out;
      h2 {
        height: 90px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        color: var(--white);
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 0.75rem;
        font-size: 1.375rem;
        text-decoration: underline;
        text-underline-offset: 0.15em;
        text-decoration-color: rgba(255, 255, 255, 0);
        transition: text-decoration-color 0.2s ease-in-out;
      }
      &:hover {
        transform: scale(1.025);
        h2 {
          text-decoration-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .md-banner-bottom {
    justify-content: center;
    padding: 30px 15px 90px 15px;
    .infoPDFFlex {
      margin: 2rem auto 0 auto;
      width: 500px;
      p {
        font-size: 18px;
        text-align: center;
      }
      img {
        float: left;
        height: 200px;
      }
    }
  }

  .md-banner-container-bottom {
    width: 100%;
    max-width: 1200px;
    flex-direction: column-reverse;
    .md-banner-text {
      margin-top: 30px;
    }
  }
  .md-banner,
  .md-banner-bottom,
  .md-banner-container,
  .md-banner-container-bottom {
    display: flex;
    flex-wrap: nowrap;
  }
  @media (min-width: 600px) {
    .md-banner-container {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;
    }
    .md-banner-image {
      display: flex;
      justify-content: flex-end;
    }
    .md-banner-text {
      margin-right: 1rem;
      max-width: 630px;
      flex: 1 1 50%;
    }
    .md-testimonial-section {
      blockquote.quote {
        flex-direction: row;
        padding: 10px 0;
      }
      blockquote p {
        text-align: left;
      }
      svg.double-quote {
        &.open-quote {
          left: -32px;
        }
        &.close-quote {
          right: -32px;
        }
      }
    }
    .md-tips-flex-wrapper {
      justify-content: space-evenly;
      .tip-button-wrapper {
        flex: 0 0 40%;
      }
    }

    .md-banner-bottom {
      background-image: linear-gradient(0deg, var(--pale-yellow), white);
      // background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position-y: 70%;
    }
    .md-banner-container-bottom {
      flex-direction: row;
      & > div {
        flex: 1 1 50%;
      }
      .md-banner-image {
        display: flex;
        align-items: flex-start;
      }
      .md-banner-text {
        margin-right: 5%;
      }
    }
  }
  @media (min-width: 900px) {
    .md-testimonial-section {
      padding-top: 50px;
      position: relative;
    }
    .md-tips-section {
      margin-bottom: 30px;
    }
    .md-tips-flex-wrapper {
      justify-content: space-evenly;
      .tip-button-wrapper {
        flex: 0 0 30%;
      }
    }
    .md-banner-bottom {
      background-position-y: 60%;
    }
    .md-banner-container-bottom {
      .md-banner-text {
        // margin-top: 8%;
      }
    }
  }
  @media (min-width: 1200px) {
    .md-tips-section {
      position: relative;
      &::before,
      &::after {
        content: "";
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 1;
      }

      .md-tips-flex-wrapper {
        justify-content: center;
        .tip-button-wrapper {
          flex: 0 0 25%;
        }
      }
    }
  }
}
.modal-header {
  &.centered {
    background-position: center -141px;
  }
  &.mtm1 {
    background-position: 0 -132px;
  }
  &.mtm2 {
    background-position: 0 -208px;
  }
  &.mtm3 {
    background-position: center -187px;
  }
  &.mtm4 {
    background-position: 0 -81px;
  }
}
.modal-content a {
  color: var(--deep-teal);
}
.md-modal {
  .modal-content {
    .flex-row {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      p {
        margin-bottom: 0;
      }
      img {
        width: 44px;
        height: 44px;
        margin-right: 6px;
      }
      ul {
        padding-left: 60px;
      }
    }
    ul {
      margin: 0;
      padding-left: 20px;
      li {
        margin-bottom: 1rem;
      }
    }
    .activity-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.75rem;
      .md-modal-accordion {
        align-self: flex-start;
        &.Mui-expanded {
          margin: 0;
        }
      }
    }
    .ideas-list {
      .md-modal-accordion {
        margin-bottom: 0.75rem;
      }
    }
  }
  .md-modal-accordion {
    background-color: var(--cg-blue);
    color: var(--white);
    border-radius: 25px;
    &:first-of-type,
    &:last-of-type {
      border-radius: 25px;
    }
    &:before {
      display: none;
    }
  }
  .md-modal-accordion-summary {
    padding: 10px;
    flex-direction: row-reverse;
    h5 {
      margin-bottom: 0;
    }
    svg path {
      fill: var(--white);
    }
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: rotate(90deg);
    }
  }

  @media (min-width: 750px) {
    .modal-content {
      .activity-list {
        grid-template-columns: 1fr 1fr;
        .md-modal-accordion {
          &:last-of-type {
            grid-column: 1 / span 2;
          }
        }
      }
    }
  }
}
.modal-content {
  .blueLink {
    color: var(--dark-teal);
  }
}
.cd-testimonial-section {
  &.infoPDFFlex {
    .blueLink {
      color: var(--dark-teal);
    }
  }
}
