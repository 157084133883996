.StressTabs .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    text-align: center;
    text-decoration: none;
    display: block;
    cursor: pointer;
    border-top-left-radius: 27px;
    font-size: 17px;
    margin-right: 14px;
    font-family: "Lato",sans-serif;
    text-transform: none;
    width: 30%;
    max-width: 200px;
    border-top-right-radius: 27px;
    margin-bottom: 10px;
    font-weight: 400;
}

.StressTabs h3 {
    text-align:left;
    font-size: 24px;
}
.StressTabs .panelFlex {
    display:flex;
    margin-top:2rem;
}
.StressTabs .panelFlex img {
    width: 200px;
}

.StressTabs .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
    margin-bottom:0px;    
    font-weight: 700;
}
.StressTabs .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary:nth-child(1) {   
    background: var(--deep-red);
    color: #fff;
    border-bottom: 5px solid var(--deep-red);
}
.StressTabs .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary:nth-child(2) {   
    background: var(--deep-pink);
    color: #fff;
    border-bottom: 5px solid var(--deep-pink);
}
.StressTabs .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary:nth-child(3) {   
    background: var(--purple);
    color: #fff;
    border-bottom: 5px solid var(--purple);
}

.StressTabs .css-1wf8b0h-MuiTabs-flexContainer {
    flex-direction: column;
    align-items: center;
}

.StressTabs .css-1aquho2-MuiTabs-indicator {
    display:none;
}

.StressTabs #simple-tabpanel-0 {
    border: 4px solid var(--deep-red);
    border-bottom-left-radius: 27px;
    border-bottom-right-radius: 27px;
}
.StressTabs #simple-tabpanel-1 {
    border: 4px solid var(--deep-pink);
    border-bottom-left-radius: 27px;
    border-bottom-right-radius: 27px;
}

.StressTabs #simple-tabpanel-2 {
    border: 4px solid var(--purple);
    border-bottom-left-radius: 27px;
    border-bottom-right-radius: 27px;
}