.gradient-bg-container {
  background: linear-gradient(0deg, #ffe6c0 0%, rgba(205, 235, 244, 0) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rqc-main {
  padding-top: 2rem;
}
@media (min-width: 600px) {
  .rqc-main {
    padding-top: 3rem;
  }
}
.rqc-main .img-wrapper {
  border: 1px solid #000000;
  border-radius: 50px;
  padding: 10px;
  width: 100%;
  max-width: 520px;
}
.rqc-main .img-wrapper img {
  border-radius: 40px;
  width: 100%;
}
.rqc-banner {
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  margin-bottom: 2rem;
  width: 100%;
  .display-h1 span {
    color: var(--teal);
  }
  @media (min-width: 600px) {
    .display-h1 {
      font-size: 3.5rem;
    }
  }
}
.rqc-banner-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
}

.rqc-banner-container #videoRight {
  background: var(--teal);
}

.rqc-testimonial-section {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 100px;
  overflow: hidden;
}
.rqc-testimonial-section .rqc-blockquote-wrapper {
  background-color: #ffffff;
  border-radius: 50px;
  padding: 50px 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 850px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
}
.rqc-testimonial-section blockquote.quote {
  position: relative;
}
.rqc-testimonial-section blockquote.EN {
  border-top: 1px solid var(--topaz);
  border-bottom: 1px solid var(--topaz);
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
  margin: 0 35px;
}

.rqc-testimonial-section blockquote p {
  font-size: 1.375rem;
  line-height: 1.4;
  margin: 0;
  text-align: center;
}
.rqc-testimonial-img {
  width: 106px;
  height: 106px;
  margin-right: 20px;
}
.rqc-questionnaire-container {
  margin: 0 1rem 40px;
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 6px 33px rgba(0, 0, 0, 0.25);
  max-width: 1200px;
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px 30px;
  position: relative;
}
.rqc-questionnaire-title {
  text-align: center;
  max-width: 750px;
  margin-bottom: 2rem;
}
.rqc-question-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  position: relative;
  height: 470px;
}
.rqc-question-card {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  transition-delay: 0.2s;
  position: absolute;
  left: 0;
  top: 0;
}
.rqc-question-card.current {
  transform: translateX(0);
  overflow: auto;
  height: inherit;
}
.rqc-question-card.answered {
  transform: translateX(-100%);
}
.rqc-question-card h3 {
  text-align: center;
  margin-bottom: 1.5rem;
}
.rqc-question-card button {
  cursor: pointer;
  background-color: var(--white);
  color: var(--dark-charcoal);
  text-align: left;
  padding: 12px 25px;
  border: 1px solid #bcbcbc;
  border-radius: 6px;
  margin-bottom: 1em;
  min-height: 86px;
}
.rqc-question-card button.selected {
  background-color: var(--banana);
  border-color: var(--dark-charcoal);
  color: var(--dark-charcoal);
}
.rqc-question-card .response {
  display: none;
  padding: 0;
}
.rqc-question-card .response.show {
  display: block;
}
.rqc-questionnaire-container .btn-back {
  background-color: var(--white);
  color: var(--dark-charcoal);
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid var(--dark-charcoal);
  position: absolute;
  top: auto;
  left: auto;
  bottom: 1rem;
  opacity: 0.5;
  transition: opacity 0.2s;
}
.rqc-questionnaire-container .btn-back:hover {
  opacity: 1;
}
.rqc-questionnaire-container .btn-back.inactive {
  display: none;
}
.rqc-questionnaire-container .btn-back svg {
  left: 13px;
  position: absolute;
}
.rqc-questionnaire-container .btn-back svg path {
  stroke: var(--dark-charcoal);
}
.rqc-care-team {
  padding: 50px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rqc-care-team-title {
  text-align: center;
  margin-bottom: 3rem;
}
.rqc-care-team-container {
  width: 100%;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: stretch;
  justify-items: center;
  column-gap: 1rem;
  row-gap: 1rem;
  margin-bottom: 2rem;
}
.rqc-care-team-card {
  width: 100%;
  max-width: 520px;
}
.rqc-care-team-card .img-wrapper {
  margin-bottom: 1.4rem;
}
.rqc-best-care-team {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 50px;
  width: 100%;
  max-width: 1200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.rqc-best-care-team .img-wrapper {
  max-width: 300px;
  margin: 0 0 2rem;
}
.rqc-best-care-team .text-wrapper h3 {
  margin-bottom: 1.5rem;
}
.video-container {
  text-align: center;
  padding: 30px 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.video-container .video-wrapper {
  width: 100%;
  max-width: 1200px;
}
.video-container .video-wrapper iframe {
  height: calc((100vw * 0.5625) - 30px);
}
.rqc-banner-bottom {
  padding: 30px 15px;
}
@media (min-width: 600px) {
  .rqc-question-wrapper {
    height: 350px;
  }
  .rqc-banner-container {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  .rqc-banner-container > div {
    flex: 1 1 50%;
  }
  .rqc-banner-text {
    margin-right: 1rem;
    max-width: 600px;
  }
  .rqc-banner-image {
    display: flex;
    justify-content: flex-end;
  }
  .rqc-testimonial-section blockquote.EN {
    flex-direction: row;
    padding: 10px 0;
  }
  .rqc-testimonial-section blockquote.EN:before {
    left: -32px;
  }
  .rqc-testimonial-section blockquote.EN:after {
    right: -32px;
  }
  .rqc-testimonial-section blockquote.EN p {
    text-align: left;
  }
  .rqc-best-care-team {
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .rqc-best-care-team .img-wrapper {
    margin: 0 calc(1.525rem + 3.3vw) 0 0;
  }
  .rqc-best-care-team .text-wrapper {
    flex: 0 0 50%;
  }
}
@media (min-width: 900px) {
  .rqc-banner {
    margin-bottom: 3rem;
    position: relative;
  }

  .rqc-questionnaire-container .btn-back {
    left: calc(50% - 365px);
    bottom: auto;
  }
  .rqc-care-team {
    padding: 100px 15px 50px;
  }
  .rqc-care-team-container {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 3rem;
  }
  .rqc-best-care-team .text-wrapper {
    flex: 1 1 auto;
  }
  .video-container {
    padding: 60px 15px;
  }
  .rqc-banner-bottom {
    padding: 60px 15px;
  }
  .rqc-banner-bottom .rqc-banner-container {
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .rqc-questionnaire-container {
    width: 100%;
    margin: 0 auto 90px;
    position: relative;
  }

  .rqc-care-team {
    position: relative;
  }
  .rqc-best-care-team .img-wrapper {
    margin-right: 4rem;
  }
  .video-container .video-wrapper iframe {
    height: calc(1200px * 0.5625);
  }
  .rqc-banner-bottom {
    position: relative;
  }
}
