.last-icon {
  max-width: none !important;
}
.quiz-banner {
  height: calc(100vh - 100px);
  min-height: 47rem;
  @media (min-width: 900px) {
    background-image: url('../../assets/images/quiz-bg-img.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.quiz-banner-container {
  background: linear-gradient(180deg, rgba(255, 221, 190, 0.4485) 0%, rgba(208, 250, 253, 0.65) 100%);
  padding: 15px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  min-height: 47rem;
  position: relative;
  @media (min-width: 1200px) {
    justify-content: flex-end;
  }
}
.quiz-content-container,
.quiz-question-container {
  background-color: rgba(255, 255, 255, .75);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 700px;
  padding: 2rem 1rem;
  // padding: 65px 40px;
  border-radius: 50px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  h1, h2 {
    margin-bottom: 2rem;
  }
  @media (min-width: 900px) {
    padding: 4rem 2rem;
    h1, h2 {
      margin-bottom: 4rem;
    }
  }
  @media (min-width: 1200px) {
    margin-right: 8vw;
  }
}
.quiz-question-container {
  max-width: 800px;
  padding-bottom: 1.5rem;
}
.start-button {
  color: #ffffff;
  cursor: pointer;
  background-color: rgba(40, 95, 166, 0.8);
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.25);
  font-size: 1.75rem;
  border: 0;
  width: 215px;
  height: 60px;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  &:hover, &:focus {
    background-color: rgba(40, 95, 166, 1);
    box-shadow: 10px 10px 16px -2px rgba(0, 0, 0, 0.25);
    transform: scale(1.03);
  }
  &:active {
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
    transform: scale(0.98);
  }
}
.button-grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr;
  gap: calc(1.325rem + 0.9vw);
  justify-items: stretch;
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }
  @media (min-width: 1200px) {
    gap: 2rem;
  }
}
.quiz-option {
  background-color: var(--white);
  border: 3px solid;
  border-radius: 25px;
  box-shadow: 0px 4px 15px 3px rgba(0, 0, 0, 0.25);
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  color: var(--black);
  .icon {
    width: calc(1.425rem + 2.1vw);
    height: calc(1.425rem + 2.1vw);
    @media (min-width: 900px) {
      margin-bottom: 1rem;
    }
    @media (min-width: 1200px) {
      width: 3rem;
      height: 3rem;
    }
  }
  p {
    margin-bottom: 0;
    text-align: left;
    margin-left: 0.5rem;
    @media (min-width: 900px) {
      text-align: center;
      margin: 0 0 1rem;
    }
  }
  .checkmark-wrapper {
    flex: 0 0 auto;
    height: 1.5rem;
    width: 1.5rem;
    margin: 0.25rem;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    @media (min-width: 900px) {
      margin-top: auto;
    }
  }
  &.selected-option {
    transform: scale(1.07);
    .checkmark-wrapper {
      opacity: 1;
    }
  }
  &.happy {
    border-color: var(--bud-green);
    .icon-check-mark path {
      fill: var(--bud-green);
    }
  }
  &.neutral {
    border-color: var(--med-yellow);
    .icon-check-mark path {
      fill: var(--med-yellow);
    }
  }
  &.worried {
    border-color: var(--gold);
    .icon-check-mark path {
      fill: var(--gold);
    }
  }
  &:hover {
    transform: scale(1.05);
  }
  @media (min-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.quiz-question-footer {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  .error-msg-wrapper {
    grid-row: 1;
    grid-column: 1 / span 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--upsdell-red);
    opacity: 0;
    transition: opacity 0.2s ease-in;
    .error-msg {
      margin: 0 0.5rem;
      display: none;
    }
    &.show { 
      opacity: 1;
      .error-msg { display: block; } 
    }
  }
  .btn-next,
  .btn-prev {
    border: 0;
    padding: 0;
    background-color: transparent;
    font-size: 1.5rem;
    font-weight: 700;
    &:hover {
      text-decoration: underline;
    }
  }
  .btn-prev {
    grid-row: 2;
    grid-column: 1;
    justify-self: start;
  }
  .btn-next {
    grid-row: 2;
    grid-column: 2;
    justify-self: end;
  }
}
.quiz-results-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(180deg, #FFFFFF 3.12%, #FFFFFF 36.46%, #D0FAFD 100%);
  display: flex;
  flex-direction: row;
  .img-panel {
    display: none;
  }
  .results-info-panel {
    padding: 1rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h2 {
      margin-bottom: 1.25rem;
    }
  }
  .learning-guide-list {
    margin-bottom: 2.5rem;
  }
  .quiz-results-option {
    display: flex;
    .quiz-results-label {
      font-size: 1.4rem;
      height: 55px;
      display: flex;
      align-items: center;
      margin-left: 1rem;
      margin-bottom: 0;
    }
  }
  .btn-redo-learning-guide {
    text-align: center;
    max-width: 300px;
    padding: 10px 15px;
    border-radius: 100px;
    border: none;
    background-color: rgba(40, 95, 166, 0.8);
    color: white;
    cursor: pointer;
    box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.25);
    &:hover, &:focus {
      background-color: rgba(40, 95, 166, 1);
      box-shadow: 10px 10px 16px -2px rgba(0, 0, 0, 0.25);
      transform: scale(1.03);
    }
    &:active {
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
      transform: scale(0.98);
    }
    @media (min-width: 600px) {
    }
    @media (min-width: 900px) {
      font-size: 1.2rem;
    }
    @media (min-width: 1200px) {
      font-size: 1.2rem;
      max-width: 365px;
    }
  }
  .print-btn-container {
    position: absolute;
    margin-top: -10px;
    top: 1rem;
    left: auto;
    right: 1rem;
    width: 60px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .print-btn {
      width: 60px;
      height: 40px;
      padding: 0;
      background-color: rgba(40, 95, 166, 0);
      border: 2px solid rgba(40, 95, 166, 1);
      border-radius: 6px;
      color: rgba(40, 95, 166, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        color: var(--white);
        background-color: rgba(40, 95, 166, 1);
      }
    }
  }
  @media (min-width: 1200px) {
    .img-panel {
      display: flex;
      align-items: center;
      .img-wrapper {
        height: 650px;
        border: 1px solid var(--black);
        border-radius: 50px;
        padding: 10px;
        &.chopped {
          border-radius: 0 50px 50px 0;
          padding-left: 0;
          margin-right: 1rem;
          img {
            border-radius: 0 40px 40px 0;
          }
        }
        img {
          border-radius: 40px;
          height: 100%;
          object-position: -550px;
          object-fit: cover;
          &.ml {
            object-position: -606px;
          }
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .img-panel {
      width: 45%;
      max-width: 650px;
    }
    .results-info-panel {
      width: 55%;
      margin-left: 2.5rem;
      p {
        font-size: 1.5rem;
        margin-bottom: 2rem;
      }
    }
  }
}
.learning-guide-print {
  padding-top: 5rem;
  .learning-guide-list {
    .quiz-results-option {
      display: flex;
      .quiz-results-label {
        font-size: 1.5rem;
        height: 45px;
        display: flex;
        align-items: center;
        margin-left: 1rem;
        margin-bottom: 0;
      }
    }
  }
}