.reducing-risk-arrow-img {
  float: right;
  margin-top: -610px;
  margin-right: 160px;
}

.image-and-data-section {
  position: relative;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 100%;
}
.modal-header {
  height:300px !important;
}
.modal-header.mtr4 {
  background-position: center -145px;
}
.modal-header.mtr2 {
  background-position: center -115px;
}
.modal-header.mtr3 {
  background-size: 120%;
  background-position: center -278px;
}
.modal-header.mtr1 {
  background-size: 120%;
  background-position: center -197px;
}
.modal-header.mtr5 {
  background-position: center -100px;
}
.modal-header.bcPhys {
  background-size:cover !important;
}
.image-data-grid-modal-body-text-section {
  margin: 0 10% 0% 10%;
  color: #000000;
  height: 500px;
  overflow: auto;
  margin-right: 0px;
}
.checkup-questions {
  width: 90%;
  min-height: 50px;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 400;
  cursor: pointer;
  background-color: #f1fffc;
  text-align: left;
  padding: 12px 25px;
  border: 1px solid #bcbcbc;
  margin-bottom: 1em;
  color: var(--black);
}

.checkup-questions.selected {
  background-color: #007da3;
  color: #ffffff;
}

.full-selection,
.any-selection,
.no-selection {
  text-align: center;
  display: none;
  padding: 5%;
}

.image-data-grids-selection {
  display: flex;
  flex-direction: column;
  width: 85%;
  margin-bottom: 15px;
}

.image-data-grids-selections-btn {
  margin: 0 auto;
  margin-top: 20px;
  padding: 10px;
  border-radius: 45px;
  width: 130px;
  font-size: 1rem;
  font-weight: 700;
  border-color: rgba(0, 125, 163, 0.77);
  border: 2px solid rgba(0, 125, 163, 0.77);
  color: rgba(0, 125, 163, 0.77);
  background-color: transparent;
  cursor: pointer;
}