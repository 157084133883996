.carousel-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  margin-bottom: 3rem;
}
.carousel-container {
  background-color: var(--white);
  box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
  width: 100%;
  max-width: 1200px;
  border-radius: 30px;
  padding: 2rem 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (min-width: 900px) {
    padding-top: 3rem;
  }
}
.fade-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  overflow: hidden;
  .carousel-slide-wrapper {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    li {
      margin-bottom: 0.75rem;
    }
  }
  @media (min-width: 600px) {
    .carousel-slide-wrapper {
      flex-direction: row;
      align-items: flex-start;
      &>div {
        flex: 0 0 calc(50% - 2rem);
        margin: 0 1rem;
      }
    }
  }
}
.carousel-image-wrapper {
  border: 1px solid var(--black);
  border-radius: 50px;
  padding: 10px;
  img {
    border-radius: 40px;
  }
}
.button-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  height: 60px;
  max-width: 200px;
  width: 100%;
  button {
    color: rgba(0, 0, 0, 0.6);
    font-size: 1.75rem;
    padding: 0;
    border: 0;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    transform: scale(1);
    width: 100%;
    &[data-active-quote="true"] {
      color: #66430f;
    }
    &:hover {
      transform: scale(1.4);
    }
    svg {
      width: 1.75rem;
      height: 1.75rem;
      position: relative;
      top: 2px;
    }
  }
}

