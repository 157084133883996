.h-container {
&.section-sisterhood {
    h2.display-h2 {
      color:var(--dark-teal);
      margin-top: 3rem;
    }
  .gradient-bg-container {
    background: linear-gradient(0deg, rgba(32, 148, 173, 0.5) -49%, rgb(255, 255, 255) 100%);
  }
    img.specialist {
      width: 500px;
      margin: 2rem 0;
      border-radius: 27px;
      border: 1px solid var(--dark-teal);
      padding: 20px;
      height: auto;
    }
    h4 {
      text-align: center;
      max-width: 700px;
      font-weight: 600;
      color: var(--dark-teal);
      font-size: 30px;
      margin: 2rem 0;
    }
    .large-capsule {
      border-left: 3px solid var(--dark-teal);
      border-right: 3px solid var(--dark-teal);
      border-top: transparent;
      border-bottom: transparent;
      border-radius: 250px;
      display: flex;
      justify-content: center;
      height: 450px;
      width: 100%;
      margin: 2rem 0 8rem 0;
      padding-top: 1.5rem;
      .video-wrapper {
        width: 80%;
        max-width: 800px;
        height: 550px;
      }
      .section-video {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
      }
      img {
        /*width: 360px;
        height: 240px;*/
          width: 252px;
          height: 158px;
          margin: 4rem 0 0 0;
          @media (min-width:400px) {
            width: 424px;
            height: 237px;
            margin:2rem 0 0 0;
          }
        }
        
      iframe {
        width: 100%;
        padding-bottom: 4rem;
        padding-top: 0;
      }
      @media (min-width:400px) {
        width:100%;
      }} 
    }     
  }
