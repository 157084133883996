.frp-healthcare-section {
  background-color: transparent;
  border-radius: 50px;
  margin: 50px 15px;
}

.frp-healthcare-head-section {
  display: flex;
  flex-direction: column; 
}

.frp-healthcare-head-section h2 {
  text-align: center;
  font-size: 1.7rem;
  line-height: 1.3;
  font-weight: 400;
  margin: 36px 0;
}

.frp-healthcare-head-section p {
  text-align: center;
  font-size: 1.3rem;
  margin: 0 1em 1em;
}

.frp-healthcare-right-for-you {
  display: grid;
  grid-template-columns: 1fr;
  padding: 15px;
}

.frp-healthcare-right-for-you p {
  text-align: center;
  font-size: 1.3rem;
  font-weight: 800;
  margin: 0 0 1em 0;
}
.frp-healthcare-right-for-you-section {
  display: grid;
  grid-template-columns: 1fr;
}
.frp-healthcare-right-for-you-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.frp-healthcare-right-for-you-div {
  width: 100%;
  min-height: 50px;
  border-radius: 8px;
  font-size: 1.125rem;
  font-weight: 400;
  cursor: pointer;
  color: var(--dark-charcoal);
  background-color: var(--white);
  text-align: left;
  padding: 12px 25px;
  border: 1px solid var(--dark-charcoal);
  margin-bottom: 1em;
}

.frp-healthcare-right-for-you-div.selected {
  background-color: var(--banana);
  border-color: var(--dark-charcoal);
  color: var(--dark-charcoal);
}

.frp-healthcare-right-for-you-section-btn {
  background-color: var(--gamboge);
  border: 1px solid var(--gamboge);
  color: var(--dark-charcoal);
  border-radius: 30px;
  height: 60px;
  width: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.375rem;
  font-weight: 700;
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.25);
  transition: filter 0.2s;
  margin: 12px 0 40px;
}
.frp-healthcare-right-for-you-section-btn:hover {
  filter: brightness(85%);
}
.frp-healthcare-right-for-you-img {
  border-radius: 40px;
  width: 100%;
  height: auto;
}
.frp-healthcare-right-for-you-img-section {
  display: none;
  overflow: hidden;
  padding: 12px;
}
.frp-healthcare-right-for-you-img-section .img-wrapper {
  border: 1px solid var(--black);
  padding: 10px;
  border-radius: 50px;
  overflow: hidden;
}
.frp-healthcare-right-for-you-img-section .img-wrapper.chopped {
  margin-right: -15px;
  border-radius: 50px 0 0 50px;
}
.img-wrapper.chopped .frp-healthcare-right-for-you-img {
  border-radius: 40px 0 0 40px;
}
.full-check,
.any-check,
.zero-check {
  display: none;
  font-size: 1.125rem;
}

@media (min-width: 900px) {
  .frp-healthcare-section {
    background-color: var(--white);
    max-width: 1200px;
    margin: 50px auto;
  }

  .frp-healthcare-right-for-you {
    padding: 20px 0 60px 20px;
  }
  .frp-healthcare-right-for-you p {
    text-align: left;
  }
  .frp-healthcare-right-for-you-section {
    grid-template-columns: 1fr 1fr;
  }
  .frp-healthcare-right-for-you-section-btn {
    padding: 12px 40px;
  }
  .frp-healthcare-right-for-you-selection {
    align-items: flex-start;
  }
  .frp-healthcare-right-for-you-img-section {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  .frp-healthcare-head-section h2 {
    font-size: 3rem;
  }
  .frp-healthcare-head-section p {
    margin: 0 1.25rem 1.25rem ;
  }
}

@media (max-width: 899px) {
  .frp-healthcare-section {
    background-color: var(--white);
  }
}
