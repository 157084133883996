html {
  scroll-behavior: smooth;
}

.Title_module_header__1f69cee0 {
  display: none;
}
.divider {
  border-top: 14px solid var(--teal);
  margin: 2rem 0;
}
.h-container {
  padding: 0;
  background-image: linear-gradient(
    270deg,
    rgba(32, 148, 173, 0.5) 0%,
    rgba(255, 255, 255, 2) 100%
  );
  margin: 0;
  &.btmbord {
    border-bottom: 14px solid var(--teal);
    padding: 60px 0px 0px 0px;
  }
  h1.display-h1 {
    font-family: "Bebas Neue", "sans-serif";
    margin-bottom: 0;
    flex: 1;
    color: var(--teal);
    text-align: center;
    @media (min-width: 600px) {
      font-size: 3.5rem;
    }
  }
  h2.display-h2 {
    font-family: "Bebas Neue", "sans-serif";
    display: block;
    color: var(--teal);
    text-align: center;
    flex: 1;
    font-size: 2.2rem;
    width: 90%;
    @media (min-width: 600px) {
      font-size: 3rem;
      width: 100%;
    }
  }
  .dark-text {
    color: var(--dark-teal);
  }

  .gradient-bg-container {
    // background: linear-gradient(180deg, rgba(234, 201, 219, 0) 0%, #f4e3ed 68.54%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-wrapper {
    border: 1px solid var(--black);
    border-radius: 50px;
    padding: 10px;
    max-width: 680px;
    img {
      object-fit: cover;
      height: 100%;
      border-radius: 40px;
    }
  }
  &.create-guide-section {
    background: var(--dark-teal);
    padding: 3rem 0;

    .gradient-bg-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .create-guide-card {
      background: linear-gradient(
        270deg,
        rgb(106 155 167) 00%,
        rgb(255, 255, 255) 59%
      );
      border-radius: 143px;
      /* border-left: 7px solid var(--teal); */
      /* border-right: 14px solid var(--teal); */
      // height: 300px;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
      display: flex;
      justify-content: center;
      width: 100%;
      max-width: 900px;
    }
    .inside-border {
      border-left: 4px solid var(--teal);
      border-right: 4px solid var(--teal);
      flex: 0 1 95%;
      border-radius: 133px;
      padding: 21px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .subtitle-guide {
        text-align: left;
        max-width: 486px;
      }
      .btn-learning-guide {
        background: var(--purple);
        color: #fff;
        border-radius: 27px;
        border-color: transparent;
        padding: 0.25rem 0.5rem;
        border-color: transparent;
        font-size: 1rem;
        @media (min-width: 600px) {
          padding: 0.5rem 1rem;
          font-size: 1.5rem;
        }
      }
    }
  }
  .home-banner-container p {
    max-width: 700px;
  }
}
.home-banner-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  flex-direction: column;
  max-width: 1100px;
  img {
    width: 100%;
    height: auto;
  }
  p {
    max-width: 660px;
  }
  .quote-container {
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;
    @media (min-width: 900px) {
      margin-top: 3rem;
      margin-bottom: 6rem;
    }
  }
}
.video-wrapper {
  width: 100%;
  max-width: 800px;
  height: 550px;
}
.section-video {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  .capsule {
    width: 300px;
    border-radius: 27px;
    padding: 0.5rem 2rem;
    text-align: center;
    color: #fff;
    font-weight: 400;
    margin-top: 1.5rem;
    background: #00677d;
  }
}
