// modal styles
.dsmes-modal {
  &.modal-theme-blue {
    .MuiBackdrop-root {
      background-color: rgba(#02829E, 0.75);
    }
    .MuiDialog-paper {
      background-color: var(--blue-diamond);
    }
    .modal-header {
      border-color: var(--pale-yellow);
    }
  }
  &.modal-theme-azure {
    .MuiBackdrop-root {
      background-color: rgba(68, 182, 160, 0.75);
    }
    .MuiDialog-paper {
      background-color: var(--azure);
    }
    .modal-header {
      border-color: var(--sea-green);
    }
  }
  &.modal-theme-pink {
    .MuiBackdrop-root {
      background-color: rgba(137, 9, 79, 0.75);
    }
    .MuiDialog-paper {
      background-color: var(--pink);
    }
    .modal-header {
      border-color: var(--maroon);
    }
  }
  &.modal-theme-yellow {
    .MuiBackdrop-root {
      background-color: rgba(254, 198, 113, 0.75);
    }
    .MuiDialog-paper {
      background-color: var(--lt-yellow);
    }
    .modal-header {
      border-color: var(--gold);
    }
    .modal-footer {
      button {
        background-color: rgba(0, 125, 163, 0.8);
        &:hover, &:active {
          background-color: rgba(0, 125, 163, 1);
        }
      }
      
    }
  }
  &.modal-theme-cyan {
    .MuiBackdrop-root {
      background-color: rgba(6, 101, 71, 0.75);
    }
    .MuiDialog-paper {
      background-color: var(--lt-cyan);
    }
    .modal-header {
      border-color: var(--cg-blue);
    }
    .modal-footer {
      button {
        background-color: var(--deep-green);
        &:hover, &:active {
          background-color: var(--deep-green);
          filter: brightness(120%);
        }
      }
    }
  }
  
  .MuiDialog-paper {
    position: relative;
    border-radius: 50px;
    overflow-x: visible;
    overflow-y: visible;
    max-width: 720px;
    margin: 1.5rem 1rem;
  }
  button.modal-close-btn {
    width: 27px;
    height: 27px;
    padding: 0;
    position: absolute;
    border: 0;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -20px;
    left: auto;
    right: -10px;
    background-color: var(--grey-green);
    color: var(--white);
    .modal-close-icon {
      path {
        color: var(--white);
      }
    }
  }
  .modal-header {
    width: 100%;
    height: 170px;
    min-height: 100px;
    background-image: none;
    background-position: center;
    background-size: 105%;
    background-repeat: no-repeat;
    border-bottom: 4px solid var(--black);
    border-radius: 50px 50px 0 0;
  }
  .modal-title {
    padding: 40px 20px 0 20px;
  }
  .modal-content {
    padding: 0 20px 40px 20px;
    overflow-y: auto;
  }
  .modal-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
    padding: 20px;
    button {
      width: 56px;
      height: 56px;
      margin: 0 35px;
      border: 0;
      padding: 0;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--cg-blue);
      transition: background-color 0.2 ease-in-out;
      svg {
        transition: transform 0.2s ease-in-out;
        transform: scale(1);
        position: relative;
      }
      &.next {
        svg {
          left: 3px;
        }
      }
      &.prev {
        svg {
          left: -3px;
        }
      }
      &:hover, &:active {
        background-color: #005872;
        svg {
          transform: scale(1.1);
        }
      }
    }
  }
  @media (min-width: 600px) {
    .modal-title {
      padding: 60px 60px 0 60px;
    }
    .modal-content {
      padding: 0 60px 60px 60px;
      overflow-y: auto;
    }
  }
  @media (min-width: 900px) {
    button.modal-close-btn {
      top: 0;
      right: -30px;
    }
  }
}