

.support-topic-section {
  background-color: var(--white);
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.support-topic-heading {
  margin-bottom: 1.75rem;
  text-align: center;
}
.supportFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*&::before {
    content: '';
    position: absolute;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 87.7 87.7' style='enable-background:new 0 0 87.7 87.7;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bfill:none;stroke:%233C8393;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;%7d %3c/style%3e%3cg%3e%3cpath class='st0' d='M49.1,82.7H38.7V8.2C38.7,5.3,41,3,43.9,3l0,0c2.9,0,5.2,2.3,5.2,5.2V82.7z'/%3e%3cline class='st0' x1='30' y1='82.7' x2='54.3' y2='82.7'/%3e%3cline class='st0' x1='61.2' y1='82.7' x2='71.6' y2='82.7'/%3e%3cpolyline class='st0' points='49.1,22.1 73.3,22.1 83.7,32.2 73.3,42.9 49.1,42.9 '/%3e%3cline class='st0' x1='57.7' y1='35.9' x2='71.6' y2='35.9'/%3e%3cline class='st0' x1='56' y1='29' x2='66.4' y2='29'/%3e%3cpolyline class='st0' points='38.7,11.7 14.4,11.7 4,21.8 14.4,32.5 38.7,32.5 '/%3e%3cline class='st0' x1='30' y1='25.5' x2='16.1' y2='25.5'/%3e%3cline class='st0' x1='31.7' y1='18.6' x2='21.3' y2='18.6'/%3e%3cpolyline class='st0' points='38.7,44.6 14.4,44.6 4,54.8 14.4,65.4 38.7,65.4 '/%3e%3cline class='st0' x1='30' y1='58.5' x2='16.1' y2='58.5'/%3e%3cline class='st0' x1='31.7' y1='51.5' x2='21.3' y2='51.5'/%3e%3c/g%3e%3c/svg%3e ");
    background-repeat:no-repeat;
      top: auto;    
      left: calc(26% - 100px);
      bottom: -1525px;
      width: 75px;
      height: 190px;
      @media (min-width:600px) {
        left: calc(50% - 600px);
        bottom: --1063px;
      width: 160px;
      }
  }*/
}
.support-topics-grid {
  width: 100%;
  max-width: 1000px;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  .topic-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none;
    align-items: center;
    height: 356px;
    background: linear-gradient(0deg, rgb(106, 155, 167) 0%, rgb(255, 255, 255) 48%);
    border-radius: 127px;
    max-width: 190px;
    .topic-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 93%;
      .chevron {
        width: 16px;
        height: auto;
      }
    .border-gradient {
      min-width: 150px;
      min-height: 150px;
      position: relative;
      top: -161px;
      border: 1px solid;
      border-radius: 50%; 
      border: 10px solid;
      // border-image-slice: 1;
      // border-width: 5px;    
      // border-image-source: linear-gradient(to left, #743ad5, #d53a9d);    
  }
    .photo {
      border-radius: 50%;
      max-width: 156px;
      height: 182px;
      min-width: 156px;
    }
    .link-title {
      font-size: 1.3rem;
      color: var(--dark-teal);
      width: 100%;
      min-height: 56px;
      margin: 0;
      text-decoration: none;
      transition: auto;
      font-weight: 700;
      margin: 3rem 0 1rem 0;
      padding: 0 1rem
    }
    }
    &:hover {
      .link-title {
        text-decoration-color: rgba(255, 255, 255, 1);
      }
    }
  }
}
@media (min-width: 350px) {
  .support-topics-grid {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 600px) {
  .support-topics-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 900px) {
  .support-topic-heading {
    margin-bottom: 2.5rem;
  }
  .support-topics-grid {
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 2rem;
  }
}
@media (min-width: 1200px) {
  .support-topics-grid {
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 3rem;
  }
}