.phys-activity-page {
  padding-top: 2rem;
  @media (min-width: 600px) {
    padding-top: 3rem;
  }
  .gradient-bg-container {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(234, 201, 219, 0.17) 34.9%,
      rgba(36, 204, 168, 0.38) 100%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pa-banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    margin-bottom: 2rem;
    // width: 100%;
    #videoRight {
      background: #218e77;
    }
  }
  .pa-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    // width: 100%;
    .img-wrapper {
      max-width: 500px;
    }
    .display-h1 span {
      color: #29bfa0;
      @media (min-width: 600px) {
        font-size: 3.5rem;
      }
    }
  }
  .img-wrapper {
    border: 1px solid #000000;
    border-radius: 50px;
    padding: 10px;
    width: 100%;
    img {
      border-radius: 40px;
      width: 100%;
    }
  }
  .pa-insight-section {
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 100px;
    overflow: hidden;
    .blockquote-wrapper {
      background-color: #ffffff;
      border-radius: 50px;
      padding: 40px 0;
      box-sizing: border-box;
      width: 100%;
      max-width: 850px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      z-index: 5;
    }
    blockquote {
      position: relative;
      border-top: 1px solid rgba(6, 33, 117, 0.31);
      border-bottom: 1px solid rgba(6, 33, 117, 0.31);
      flex-wrap: nowrap;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 0px;
      margin: 0 35px;
      h2 {
        font-size: 1.375rem;
        margin-bottom: 0;
        font-family: inherit;
      }
      p {
        margin-bottom: 0;
        font-size: 1.375rem;
        line-height: 1.4;
      }
    }
  }
  .pa-tips-section {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    .modal-header {
      &.mt1 {
        background-position: center 0;
      }
    }
  }

  .pa-tips-flex-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    .tip-button-wrapper {
      flex: 0 0 100%;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
    }
    button.tip {
      padding: 0;
      width: 270px;
      height: 400px;
      background-size: cover;
      background-position: center;
      border: 0;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      overflow: hidden;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      transform: scale(1);
      transition: transform 0.2s ease-in-out;
      h2 {
        height: 90px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        color: var(--white);
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 0.75rem;
        font-size: 1.375rem;
        text-decoration: underline;
        text-underline-offset: 0.15em;
        text-decoration-color: rgba(255, 255, 255, 0);
        transition: text-decoration-color 0.2s ease-in-out;
      }
      &:hover {
        transform: scale(1.025);
        h2 {
          text-decoration-color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .pa-bottom-banner-container {
    display: flex;
    justify-content: center;
    padding: 30px 15px 90px 15px;
  }
  .pa-bottom-banner {
    display: flex;
    width: 100%;
    max-width: 1200px;
    flex-direction: column-reverse;
    .pa-banner-text {
      margin-top: 30px;
    }
  }

  @media (min-width: 600px) {
    .pa-banner {
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;
    }
    .pa-banner-image {
      display: flex;
      justify-content: flex-end;
      width: 60%;
    }
    .pa-banner-text {
      margin-right: 1rem;
      max-width: 600px;
      flex: 1 1 50%;
      h1.display-h1 {
        margin-bottom: calc(1.325rem + 0.9vw);
      }
      p {
        line-height: 1.6;
      }
    }
    .pa-insight-section {
      blockquote {
        justify-content: flex-start;
        align-items: flex-start;
        h2,
        p {
          text-align: left;
        }
      }
    }
    .pa-tips-flex-wrapper {
      justify-content: space-evenly;
      .tip-button-wrapper {
        flex: 0 0 40%;
      }
    }
    .pa-bottom-banner-container {
      background-image: linear-gradient(0deg, var(--lt-pink), var(--lt-pink));
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position-y: 60%;
      padding-top: 100px;
    }
    .pa-bottom-banner {
      display: flex;
      width: 100%;
      max-width: 1200px;
      flex-direction: row;
      .pa-banner-image {
        display: flex;
        align-items: flex-start;
      }
      .pa-banner-text {
        margin-right: 5%;
        p {
          line-height: 1.6;
        }
      }
    }
  }
  @media (min-width: 900px) {
    .pa-banner-container {
      margin-bottom: 3rem;
      .pa-banner-text {
        position: relative;
        p {
          font-size: 1.325rem;
        }
      }
    }

    .pa-tips-section {
      margin-bottom: 30px;
    }
    .pa-tips-flex-wrapper {
      justify-content: space-evenly;
      .tip-button-wrapper {
        flex: 0 0 30%;
      }
    }
    .pa-bottom-banner {
      .pa-banner-text {
        margin-top: 100px;
        p {
          font-size: 1.325rem;
          max-width: 520px;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    .pa-banner-container {
      margin-bottom: 5rem;
    }
    .pa-banner-text {
      h1.display-h1 {
        margin-bottom: 2rem;
      }
    }
    .pa-insight-section {
      position: relative;
      overflow: visible;
    }
    .pa-tips-section {
      position: relative;

      .pa-tips-flex-wrapper {
        justify-content: center;
        .tip-button-wrapper {
          flex: 0 0 25%;
        }
      }
    }
    .pa-bottom-banner-container {
      position: relative;
    }
  }
}
.pa-modal {
  .modal-content {
    .flex-row {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      p {
        margin-bottom: 0;
      }
      img {
        width: 44px;
        height: 44px;
        margin-right: 6px;
      }
      ul {
        padding-left: 60px;
      }
    }
    ul {
      margin: 0;
      padding-left: 20px;
      li {
        margin-bottom: 1rem;
      }
    }
    .activity-list {
      display: grid;
      grid-template-columns: 1fr;
      gap: 0.75rem;
      .pa-modal-accordion {
        align-self: flex-start;
        &.Mui-expanded {
          margin: 0;
        }
      }
    }
    .ideas-list {
      .pa-modal-accordion {
        margin-bottom: 0.75rem;
      }
    }
  }
  .pa-modal-accordion {
    background-color: var(--patricks-blue);
    color: var(--white);
    border-radius: 25px;
    &:first-of-type,
    &:last-of-type {
      border-radius: 25px;
    }
    &:before {
      display: none;
    }
  }
  .pa-modal-accordion-summary {
    padding: 10px;
    flex-direction: row-reverse;
    h5 {
      margin-bottom: 0;
    }
    svg path {
      fill: var(--white);
    }
    .MuiAccordionSummary-expandIconWrapper {
      flex: 0 0 17%;
    }
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      transform: rotate(90deg);
    }
  }
  @media (min-width: 750px) {
    .modal-content {
      .activity-list {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
