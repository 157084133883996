.external-link {
  display: inline;
  padding: 0;
  background: transparent;
  border: 0;
  color: inherit;
  font-weight: 700;
  text-decoration: underline;
  
}
.external-link-icon {
  display: inline-flex;
  align-items: center;
  svg {
    font-size: 1rem;
    position: relative;
    top: 2px;
  }
  
}
.external-link-modal {
  .modal-title {
    border-bottom: 4px solid var(--cg-blue);
    padding-top: 2rem;
    margin-bottom: 2rem;
    h3 {
      margin-bottom: 1.25rem;
    }
  }
  .modal-content {
    li {
      margin-bottom: 0.75rem;
    }
  }
  .modal-footer {
    justify-content: flex-end;
    .btn-cancel, .btn-continue {
      width: auto;
      border-radius: 28px;
      padding: 0 2rem;
      color: var(--white);
      margin: 0;
      margin-right: 1.5rem;
    }
    .btn-cancel {
      background-color: var(--pastel-red);
      &:hover {
        background-color: var(--pastel-red);
        filter: brightness(85%);
      }
    }
  }
}