.dsmes-gradient-container {
  background: linear-gradient(180deg, #fff,
  rgba(255, 230, 192, 0.554),
  #FFE6C0 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dsmes-banner h1 {
  padding-top: 5%;
}
.display-h1 span.yellow{
  color: #ffbd5a;
}
@media (min-width:600px) {
  .display-h1 {
    font-size:3.5rem;
  }
}
.dsmes-banner-text ul {
  padding-left: 1rem;
}
.dsmes-banner-text ul li {
  margin-bottom: 0.5rem;
}
.dsmes-checkbox-container{
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dsmes-checkbox-container .dsmes-checkbox:after {
  left: 8px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.dsmes-checkbox-container:hover input ~ .dsmes-checkbox {
  background-color: #F5F5F5;
}

.dsmes-checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.dsmes-checkbox-container input:checked ~ .dsmes-checkbox:after {
  display: block;
}

.dsmes-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.dsmes-checkbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 25px;
  border: solid gray;
  border-radius: 10px;
  background-color: #ffff;
}

.dsmes-banner {
  width: 100%;
  padding: 0 15px;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 2rem;
}
@media (min-width: 600px) {
  .dsmes-banner {
    padding-top: 3rem;
  }
}

.dsmes-banner-container {
  display: flex;
  flex-direction: column;
}
.dsmes-banner-image-wrapper {
  border: 1px solid #062175;
  border-radius: 50px;
  padding: 10px;
  max-width: 520px;
  margin-top: 5%;
}
.dsmes-banner-img {
  border-radius: 40px;
}

.dsmes-container-banner-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dsmes-banner-bottom {
  display: flex;
  flex-direction: column-reverse;
  padding: 20px 30px;
  align-items: flex-start;
}

.dsmes-banner-bottom ul {
  padding-left: 4%;
  margin-bottom: 1rem;
}

.dsmes-banner-bottom ul li {
  margin-bottom: 0.5rem;
}
.dsmes-banner-bottom p, .dsmes-banner-bottom h3 {
  max-width: 90%;
  padding-left: 3%;
}
.dsmes-banner-text {
  max-width: 650px;
}

.dsmes-text {
  text-align: center;
}

.dsmes-mobile-show{
  display: none;
}

.dsmes-highlight-text {
  padding: 20px 30px;
  background-color: #FFEFAE;
  display: flex;
  margin-top: 5%;
}

.dsmes-testimonial-section {
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}



.dsmes-testimonial-section .blockquote-wrapper {
  background-color: #FFFFFF;
  border-radius: 50px;
  padding: 50px 0;
  box-sizing: border-box;
  width: 90%;
  max-width: 850px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
}
.dsmes-testimonial-section blockquote.quote {
  position: relative;
}
.dsmes-testimonial-section blockquote.EN {
  border-top: 1px solid #fec671;
  border-bottom: 1px solid #fec671;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
  margin: 0 35px;
}

.dsmes-quote-container{
  margin: auto;
  border-radius: 30px;
  width: 55%;
  display: flex;
  background-color: #FFF;
  margin-top: 3%;
  margin-bottom: 3%;  
  -moz-box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
	-webkit-box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
	box-shadow: 5px 5px 5px rgba(68,68,68,0.6);
}

.dsmes-testimonial-section blockquote p {
  line-height: 1.4;
  margin: 0;
  text-align: center;
}
.dsmes-testimonial-img {
  width: 106px;
  height: 106px;
  margin-right: 20px;
}

.vector-learning {
  align-self: flex-start;
  padding-left: 2%;
}

.dsmes-hr {
  border: 5px solid #FED834;
  margin-top: 160px;
  position: relative;
  width: 100%;
}




.dsmes-boxes-section {
  background: linear-gradient(
    180deg,
    #ffff,
    rgba(255, 230, 192, 0.554),
    #FFE6C0
  );
  padding-bottom: 50px;
}

.dsmes-boxes-section-block {
  padding: 0px;
  width: 50%;
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 300px 1fr;
}

.dsmes-boxes-section-block-img {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  position: relative;
  margin-top: -150px;
}

.dsmes-boxes-section-block-text {
  margin-top: -100px;
  margin-left: 50px;
}

.dsmes-boxes-section-block-text h1 {
  margin: 0;
}

.dsmes-boxes-section-block-text p {
  margin-top: 30px;
}
.dsmes-text-boxes-container {
  justify-content: center;
  padding: 5% 10%;
}
.dsmes-text-boxes {
  padding: 10%;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  border: solid #FED834;
}

.vector-learning-2 {
  position: absolute;
  top: 1100px;
  right: 10vw;
  display: none;
}

.vector-learning-3 {
  position: absolute;
  right: 15vw;
  top: 175vh;
  display: none;
}

.vector-learning-4 {
  display: none;
}

.vector-learning-5 {
  position: absolute;
  right: 1vw;
  top: 230vh;
  display: none;
}

@media (min-width: 1200px) {
  .dsmes-quote-container::after, .dsmes-boxes-section::after, .dsmes-banner-text::after, .dsmes-text-boxes-container::after, .dsmes-boxes-section-block-text::after {
    display: block;
  }
  .vector-learning-2, .vector-learning-3, .vector-learning-4, .vector-learning-5 {
    display: block;
  }
}

@media (max-width: 1360px) {
  .dsmes-boxes-section-block {
    width: 80%;
  }
}

@media (max-width: 799px) {
  .dsmes-quote-container {
    width: 80%;
  }
  .dsmes-mobile-show {
    display: block;
  }
  .dsmes-mobile-no-show {
    display:none
  }
}

@media (min-width: 600px) {

  .dsmes-banner-text {
    padding-right: 2%;
  }

  .dsmes-banner-container,
  .dsmes-banner-bottom {
    flex-direction: row;
  }
  .dsmes-banner-bottom {
    width: 80%;
    justify-content: space-between;
    padding: 65px 0 50px;
  } 
  .dsmes-banner-bottom>div {
    margin: 15px;
  }

  .dsmes-testimonial-section blockquote.EN {
    flex-direction: row;
    padding: 10px 0;
  }
  .dsmes-testimonial-section blockquote.EN:before { left: -32px; }
  .dsmes-testimonial-section blockquote.EN:after { right: -32px; }
  .dsmes-testimonial-section blockquote.EN p {
    text-align: left;
  }
  .dsmes-healthcare-questions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-items: start;
    padding: 0  0  20px 0;
  }
  .dsmes-healthcare-questions .grid-image-container {
    grid-row: 1 / 3;
    grid-column: 1;
    z-index: 5;
    justify-content: flex-end;
    padding-left: 15px;
    margin-right: 10%;
  }
  .dsmes-healthcare-questions .grid-image-container::before {
    display: none;
  }
  .dsmes-healthcare-questions .section-header {
    border-bottom: 14px solid #E48912;
    grid-row: 1;
    grid-column: 1 / 3;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .dsmes-healthcare-questions .section-header h2 {
    grid-column: 2;
    max-width: 620px;
    width: 100%;
    margin: 0 0 24px;
    padding-right: 15px;
  }

  .dsmes-healthcare-questions .text-block {
    grid-column: 2;
    grid-row: 2;
    width: 100%;
    max-width: 600px;
    padding-right: 15px;
  }
}
@media (min-width: 900px) {
  .dsmes-highlight-text {
    margin-top: 0;
  }
  .dsmes-banner {
    margin-bottom: 50px;
  }

  .dsmes-healthcare-questions .section-header {
    position: relative;
  }
  .dsmes-healthcare-questions h2 {
    position: relative;
  }
  .dsmes-banner-bottom {
    position: relative;
  }
}

@media (max-width: 799px) {
  .dsmes-boxes-section-block {
    display: block;
    width: 85%;
  }
  .dsmes-boxes-section-block-text {
    margin-top: 15px;
    margin-left: 0px;
  }
}
