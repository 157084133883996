.fp-preparing-meet-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    margin-bottom: 50px;
}
.fp-preparing-meet-section h1 {
    font-size: 2.5rem;
    font-weight: 400;
    max-width: 700px;
    line-height: 1.125;
}
.fp-preparing-meet-section>p {
    max-width: 70%;
    font-size: 1.4rem;
}
.fp-preparing-meet-section .more-info {
    max-width: 660px;
    font-size: 1.375rem;
    font-weight: 700;
    margin: 0 0 30px;
}
.fp-preparing-meet-section .steps-list {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 30px;
    row-gap: 30px;
    margin-bottom: 30px;
    margin-top: 70px;
}

.fp-preparing-meet-section .step-card {
    background-color: #FFFFFF;
    box-sizing: border-box;
    width: 100%;
    max-width: 390px;
    position: relative;
    text-align: left;
    border: 1px solid var(--gamboge);
    border-radius: 6px;
    padding: 25px 75px 25px 25px;
    box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.25);
    ul {
        padding-left: 1.25rem;
        li {
            margin-bottom: 0.5rem;
        }
    }
}
.fp-preparing-meet-section .step-card .step-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 60px;
    height: 60px;
}
.fp-preparing-meet-section .step-card h2 {
 font-size: 1.375rem;
 font-weight: 700;
 line-height: 1.2;
 margin: 0 0 20px;
}
.fp-preparing-meet-section .step-card p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0;
}
.fp-preparing-meet-section .btn-patient-tips {
    background-color: var(--gamboge);
    color: var(--dark-charcoal);
    border-radius: 30px;
    height: 60px;
    padding: 0 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.375rem;
    font-weight: 700;
    box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.25);
    transition: filter 0.2s;
}
.fp-preparing-meet-section .btn-patient-tips:hover {
    filter: brightness(85%);
}
@media (min-width: 600px) { 
    
    .fp-preparing-meet-section h1 {
        font-size: 2rem;
    }
    .fp-preparing-meet-section .steps-list  {
        grid-template-columns: 1fr 1fr;
        .questions {
            grid-row: 1 / span 4;
            grid-column: 1;
        }
        .diabetes-programs {
            grid-row: 5;
            grid-column: 1 / span 2;
            max-width: 810px;
        }
        .medications {
            grid-row: 1;
            grid-column: 2;
        }
        .support {
            grid-row: 2;
            grid-column: 2;
        }
        .terms {
            grid-row: 3;
            grid-column: 2;
        }
        .transportation {
            grid-row: 4;
            grid-column: 2;
        }
    }
}
@media (min-width: 900px) {
    .fp-preparing-meet-section {
        background-position: 0% 18%;
    }
    .fp-preparing-meet-section h1 {
        font-size: 3rem;
    }
    .fp-preparing-meet-section .steps-list  {
        grid-template-columns: minmax(auto, 480px) minmax(auto, 390px) minmax(auto, 390px);
        grid-template-rows: 1fr 1fr 1fr;
        grid-auto-flow: column;
        .questions {
            grid-row: 1 / span 3;
            grid-column: 1;
            max-width: 480px;
        }
        .diabetes-programs {
            grid-row-start: 3;
            grid-column: 2 / span 2;
            max-width: 810px;
        }
        .medications {
            grid-row: 1;
            grid-column: 2;
        }
        .support {
            grid-row: 1;
            grid-column: 3;
        }
        .terms {
            grid-row: 2;
            grid-column: 2;
        }
        .transportation {
            grid-row: 2;
            grid-column: 3;
        }
    }
}
@media (min-width: 1200px) {
    .fp-preparing-meet-section {
        background-position: 0% 20%;
    }
    .fp-preparing-meet-section h1 {
        font-size: 3.2rem;
    }
}
