nav {
  .row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    &.nav-row {
      border-top: 1px solid #bdbdbd;
      border-bottom: 1px solid #bdbdbd;
      box-shadow: 0px 5px 9px -9px rgba(0, 0, 0, 0.5);
    }
    .container {
      width: 100%;
      // max-width: 1200px;
      display: flex;
      flex-direction: row;
      &.logo-wrapper {
        justify-content: flex-start;
        padding: 0.5rem 0;
      }
      &.main-nav {
        justify-content: space-between;
      }
    }
  }
  .main-nav-list {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    li {
      display: flex;
      align-items: center;
      margin-right: 1rem;
      a {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        font-size: 1.125rem;
        color: var(--dark-charcoal);
        font-weight: 700;
        transform: scale(1);
        transition: transform 0.2s ease-in-out;
        &:hover {
          transform: scale(1.05);
          color: var(--black);
        }
      }
    }
  }
  .main-header-logo {
    .logoimage {
      display: none;
    }
    @media (min-width: 900px) {
      .logoimage {
        display: initial;
      }
      .mobilelogoimage {
        display: none;
      }
    }
  }
}
.main-menu-wrapper {
  position: relative;
  .main-menu-btn {
    border: 0;
    padding: 0;
    background-color: transparent;
    display: flex;
    align-items: center;
    color: var(--dark-charcoal);
    transform: scale(1);
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
      color: var(--black);
    }
    .topics-label {
      font-weight: 700;
    }
    svg.menu-icon {
      transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1);
      path {
        transition:
          transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
          stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
          stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
        &:nth-child(1) {
          transform-origin: 36% 40%;
        }
        &:nth-child(2) {
          stroke-dasharray: 29 299;
        }
        &:nth-child(3) {
          transform-origin: 35% 63%;
        }
        &:nth-child(4) {
          stroke-dasharray: 29 299;
        }
        &:nth-child(5) {
          transform-origin: 61% 52%;
        }
        &:nth-child(6) {
          transform-origin: 62% 52%;
        }
      }
    }
    &.active {
      svg.menu-icon {
        transform: rotate(90deg);
        path {
          &:nth-child(1) {
            transform: translateX(9px) translateY(1px) rotate(45deg);
          }
          &:nth-child(2) {
            stroke-dasharray: 225 299;
            stroke-dashoffset: -72px;
          }
          &:nth-child(3) {
            transform: translateX(9px) translateY(1px) rotate(-45deg);
          }
          &:nth-child(4) {
            stroke-dasharray: 225 299;
            stroke-dashoffset: -72px;
          }
          &:nth-child(5) {
            transform: translateX(9px) translateY(1px) rotate(-45deg);
          }
          &:nth-child(6) {
            transform: translateX(9px) translateY(1px) rotate(45deg);
          }
        }
      }
    }
  }
  .main-menu-panel {
    background-color: var(--white);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    position: absolute;
    top: auto;
    left: auto;
    right: 0;
    z-index: 1001;
    width: 280px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 1rem;
  }
  .main-menu {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      border: 2px solid transparent;
      height: 50px;
      border-radius: 25px;
      margin-bottom: 0.25rem;
      a {
        font-weight: 500;
        width: 100%;
        height: 100%;
        padding: 0 1rem;
        font-size: 1rem;
        text-decoration: none;
        display: flex;
        align-items: center;
      }
      &:hover {
        border-color: rgba(0, 125, 163, 0.2);
      }
      &.current {
        border-color: var(--lapis-lazuli);
        a {
          font-weight: 700;
        }
      }
    }
  }
  .topics-label {
    display: none;
  }
  @media (min-width: 900px) {
    .topics-title {
      display: none;
    }
    .topics-label {
      display: initial;
    }
  }
}
.fab-wrapper {
  position: fixed;
  border-radius: 50%;
  bottom: 1rem;
  right: 1rem;
  width: 280px;
  height: 40px;
  background-color: var(--white);
  z-index: 1000;
  @media (min-width: 900px) {
    width: 280px;
    height: 56px;
    right: 1.5rem;
    bottom: 1.5rem;
  }
  .fab {
    width: 280px;
    height: 40px;
    border: 0;
    border-radius: 20px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
    background-color: var(--lapis-lazuli);
    color: var(--white);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 700;
    svg {
      transform: rotate(0);
      transition: transform 0.3s ease-in-out;
    }
    @media (min-width: 900px) {
      width: 280px;
      height: 56px;
      border-radius: 27px;
    }
    &.active {
      svg {
        transform: rotate(225deg);
      }
    }
  }
  a.fab {
    text-decoration: underline;
    text-underline-offset: 0.15em;
    text-decoration-color: rgba(255, 255, 255, 0);
    transition: text-decoration-color 0.2s ease-in-out;
    font-weight: 700;
    &:hover {
      text-decoration-color: rgba(255, 255, 255, 1);
    }
  }
  .learning-guide-collapse-panel {
    background-color: var(--white);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border: 3px solid var(--lapis-lazuli);
    border-radius: 25px;
    padding: 1rem;
    position: absolute;
    top: auto;
    left: auto;
    right: 0;
    bottom: 48px;
    z-index: 20;
    width: 280px;
    .learning-guide-menu {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        border: 2px solid transparent;
        height: 50px;
        border-radius: 25px;
        margin-bottom: 0.25rem;
        a {
          font-weight: 500;
          width: 100%;
          height: 100%;
          padding: 0 1rem;
          font-size: 1rem;
          text-decoration: none;
          display: flex;
          align-items: center;
        }
        &:hover {
          border-color: rgba(0, 125, 163, 0.2);
        }
        &.current {
          border-color: var(--lapis-lazuli);
          a {
            font-weight: 700;
          }
        }
      }
    }
    @media (min-width: 900px) {
      bottom: 64px;
    }
  }
}

.skip-link {
  margin-right: 1rem;
  position: absolute;
  transform: translateX(-200%);
  transition: transform 0.3s;

  &:focus {
    position: static;
    transform: translateX(0);
  }
}
