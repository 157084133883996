.StressOptions .MuiTabs-flexContainer button {
    text-align: center;
    text-decoration: none;
    display: block;
    cursor: pointer;
    border-radius: 32px;
    background: #fff;
    margin-bottom: 14px;
    font-family: "Lato",sans-serif;
    text-transform: none;
    color: #000;
    padding: 11px 14px;
    width: 350px;
    max-width: 448px;
    min-height: 41px;
    border: 4px solid transparent;
    margin-bottom: 10px;
    font-weight: 900;
    font-size: 18px;
    @media (min-width:600px) {
        
    font-size: 28px;
    }
}

.StressOptions .MuiTabs-flexContainer button.Mui-selected {
    border: 4px solid var(--deep-red);
    background: rgba(250, 156, 168, 0.16);
    color: #000;
}

.StressOptions .stressOptionTab.expanded {
    width:445px;
}

.StressOptions .MuiTabs-flexContainer {
    flex-direction: column;
    align-items: center;
}

.StressOptions .MuiTabs-indicator {
    display:none;
}

.StressTabs #simple-tabpanel-0, .StressTabs #simple-tabpanel-1, .StressTabs #simple-tabpanel-2 {
    border-bottom-left-radius: 27px;
    border-bottom-right-radius: 27px;
    margin-top: -5px;
}

.StressTabs #simple-tabpanel-0 {
    border: 7px solid var(--deep-red);
}
.StressTabs #simple-tabpanel-1 {
    border: 7px solid var(--deep-pink);
}

.StressTabs #simple-tabpanel-2 {
    border: 7px solid var(--purple);
}