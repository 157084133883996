.community-gradient-container {
  background: linear-gradient(
    180deg,
    #fff,
    #cdebf463 30%,
    rgb(32 148 173 / 44%) 100%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
}
#videoRight {
  background: var(--teal);
}
.community-gradient-container .MuiAccordion-root {
  background: transparent;
  border-radius: 20px;
  margin: 0 auto 3rem auto;
  color: #000;
  max-width: 1100px;
  width: 62%;
  clear: both;
}

.community-gradient-container .MuiAccordionDetails-root {
  background: #fff;
  box-shadow: 2px 2px 2px #ddd;
}
.community-gradient-container .MuiAccordionDetails-root p {
  color: black;
}
.community-gradient-container .MuiAccordionDetails-root p.strong {
  font-weight: 700;
  color: var(--dark-teal);
  font-size: 20px;
}
.community-gradient-container .MuiAccordionDetails-root a {
  color: var(--dark-teal);
}
.community-gradient-container .MuiAccordionDetails-root h3 {
  color: var(--teal);
}
.community-gradient-container .MuiAccordionDetails-root ul li {
  color: black;
}

.community-gradient-container .MuiAccordionDetails-root h3.paleGreenBanner {
  color: var(--dark-teal);
  font-family: "Bebas Neue", "sans-serif";
  text-transform: uppercase;
  width: 100%;
  text-align: left;
  background: #e3f1f5;
  padding: 1rem 0.5rem;
  border-radius: 10px;
}
.community-gradient-container .MuiAccordionDetails-root .flexGrid {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 600px) {
  .community-gradient-container .MuiAccordionDetails-root .flexGrid {
    flex-direction: row;
  }
}
.community-gradient-container .MuiAccordionDetails-root .flexGrid .gridItem {
  flex: 1 1 40%;
  min-height: 200px;
  max-width: 300px;
  margin: 2rem;
  background: #e3f1f5;
  color: var(--dark-teal);
  font-size: 18px;
  font-weight: 700;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.community-gradient-container .MuiAccordionDetails-root .LikeWhatYouSee {
  width: 80%;
  border: 4px solid var(--dark-teal);
  padding: 1rem;
  margin: 2rem 0;
  background: #e3f1f5;
  border-radius: 50px;
  margin: 2rem auto;
  text-align: center;
}

.community-gradient-container .MuiAccordionDetails-root .LikeWhatYouSee h3 {
  text-transform: uppercase;
  color: var(--dark-teal);
  font-size: 24px;
  font-family: "Bebas Neue", "sans-serif";
}
.community-gradient-container
  .MuiAccordionDetails-root
  .LikeWhatYouSee
  p.strong {
  font-weight: 700;
  color: var(--dark-teal);
  font-size: 20px;
}
.community-gradient-container .MuiAccordionDetails-root .emergencyFlexList {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
@media (min-width: 350px) {
  .community-gradient-container .MuiAccordionDetails-root .emergencyFlexList {
    flex-direction: row;
  }
}

.community-gradient-container
  .MuiAccordionDetails-root
  .emergencyFlexList
  .emergListLeft {
  flex: 0 0 50%;
  margin-right: 2rem;
}
.community-gradient-container .MuiAccordionDetails-root .emergencyListItem {
  display: flex;
  justify-content: flex-start;
  background: #e3f1f5;
  margin: 0.5rem 0;
  padding: 0.8rem;
}
.community-gradient-container
  .MuiAccordionDetails-root
  .emergencyListItem.leftIndent {
  padding-left: 2rem;
}
.community-gradient-container
  .MuiAccordionDetails-root
  .emergencyListItem
  span {
  flex: 0 0 20px;
  color: black;
}
.community-gradient-container .MuiAccordionDetails-root .emergencyListItem div {
  flex: 1;
  align-self: flex-start;
  color: black;
  width: 45%;
}
.community-gradient-container
  .MuiAccordionDetails-root
  .emergencyFlexList
  .coloredSquare {
  height: 500px;
  max-height: 500px;
  background: #c0aeb4;
  flex: 1 1 40%;
  display: block;
  border-radius: 50px;
}
.community-gradient-container
  .MuiAccordionDetails-root
  .emergencyFlexList
  .coloredSquare
  img {
  max-width: 300px;
  margin: 0 auto;
  width: 36%;
  margin-top: 2rem;
}
.community-gradient-container
  .MuiAccordionDetails-root
  .emergencyFlexList
  .coloredSquare
  p {
  text-align: center;
  font-size: 20px;
  margin: 0 2rem;
}
.riskTestWrapper {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.riskTestWrapper .btn {
  width: 200px;
  padding: 0.5rem 1rem;
  color: white;
  text-transform: uppercase;
}
.MuiAccordionSummary-expandIconWrapper svg {
  width: 3rem;
  height: 4rem;
  fill: #2094ad;
}

.community-support-title {
  padding: 3%;
  background-color: #007da3;
  width: 100%;
}
.community-support-title h2,
.community-support-title p {
  color: white;
  text-align: center;
}

.community-support-text-container {
  max-width: 600px;
  margin: auto;
}

.support-orgs-container {
  padding: 5% 10% 0%;
}
.support-container {
  background: linear-gradient(
    180deg,
    rgba(32, 148, 173, 0.5) -49%,
    rgb(255, 255, 255) 100%
  );
  border-bottom: 14px solid var(--dark-teal);
  padding-bottom: 4rem;
}
.org-logo-container {
  -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  background-color: white;
  height: fit-content;
  padding: 2%;
}
.org-text-container {
  height: fit-content;
}

.support-button.xyz {
  background: var(--dark-teal);
  color: white;
  padding: 0.8rem 3rem;
  border-radius: 20px;
}
.support-orgs-container h3 {
  padding-top: 5%;
}
.fit-org-beyond {
  height: fit-content;
}
.org-logo {
  margin: auto;
}
.community-support-title {
  padding: 3%;
  background-color: #007da3;
}
.community-support-title h2 {
  color: white;
  text-align: center;
}
.community-support-title p {
  color: white;
  text-align: left;
}

.community-support-text-container {
  width: 90%;
  margin: auto;
}
.community-banner {
  width: 100%;
  padding: 0 15px;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 2rem;
}
.display-h1 span {
  color: var(--teal);
}

.community-banner-img {
  border-radius: 40px;
  width: 100%;
}
.accordionDetails .community-banner-img {
  border-radius: 40px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.community-banner-container-bottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 20px 30px;
}
.community-banner-container-bottom p {
  line-height: 1.2;
  max-width: 540px;
}
.community-banner-text {
  width: 100%;
  max-width: 650px;
}
@media (min-width: 600px) {
  .community-banner {
    padding-top: 3rem;
  }
  .display-h1 {
    font-size: 3.5rem;
  }
}
.community-banner h1 {
  margin-top: 0;
}
.community-banner-container {
  display: flex;
  flex-direction: column;
}
.community-banner-image-wrapper {
  border: 1px solid #062175;
  border-radius: 50px;
  padding: 10px;
  width: 100%;
  max-width: 520px;
  margin: 2rem auto;
}

.community-support-text-container .community-banner-image-wrapper {
  max-width: 600px;
}
@media (min-width: 600px) {
  .community-banner-container {
    flex-direction: row;
  }
}

@media (min-width: 900px) {
  .community-banner-image-wrapper {
    width: 110%;
  }
  .community-banner-bottom-image-wrapper {
    width: 40%;
  }
  .community-banner {
    margin-bottom: 50px;
  }
  .connecting-mobile-no-show {
    display: block;
  }
  .community-banner-text {
    padding-right: 60px;
  }
  .community-banner h1 {
    /* font-size: 4rem; */
    padding-bottom: 10%;
  }
  .community-banner-container-bottom {
    position: relative;
  }
}
@media (min-width: 1200px) {
}
@media (max-width: 1600px) {
  .community-banner-image-wrapper::after {
    right: 3%;
  }
}
