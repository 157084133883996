.connecting-gradient-container {
  background: linear-gradient(0deg, #fcf2ff, #dbf0f6, #ffffff);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.connecting-banner {
  width: 100%;
  padding: 0 15px;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 2rem;
}
.connecting-banner .display-h1 span {
  color: #a84fc3;
}
@media (min-width: 600px) {
  .connecting-banner {
    padding-top: 3rem;
  }
  .connecting-banner .display-h1 {
    font-size: 3.5rem;
  }
}
.connecting-banner h1 {
  margin-top: 0;
}
.connecting-banner-container {
  display: flex;
  flex-direction: column;
}

.connecting-banner-container #videoRight {
  background: #a84fc3;
}
.connecting-banner-image-wrapper {
  border: 1px solid #062175;
  border-radius: 50px;
  padding: 10px;
  width: 100%;
  max-width: 520px;
}
.connecting-banner-img {
  border-radius: 40px;
  width: 100%;
}
.connecting-banner-container-bottom {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 20px 30px;
}
.connecting-banner-container-bottom p {
  line-height: 1.2;
  max-width: 540px;
}
.question-options {
  margin-top: 17px;
  display: flex;
  background: #f1f2f2;
}
.connecting-banner-text {
  width: 100%;
  max-width: 650px;
}
.connecting-text {
  text-align: center;
}
.connecting-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  margin-top: 8px;
}
.connecting-block-text-points {
  grid-template-columns: 30px 1fr;
  grid-gap: 20px;
  padding-top: 0%;
}
.connecting-testimonial-section {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 100px;
  overflow: hidden;
}
.connecting-testimonial-section blockquote {
  position: relative;
  border-top: 1px solid rgba(6, 33, 117, 0.31);
  border-bottom: 1px solid rgba(6, 33, 117, 0.31);
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px 0px;
  margin: 0 35px;
}
.connecting-testimonial-section .blockquote-wrapper {
  background-color: #ffffff;
  border-radius: 50px;
  padding: 40px 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 850px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 5;
}
ul.numbers {
  list-style-type: decimal;
}
ul.numbers li {
  margin-bottom: 1rem;
}
.connecting-testimonial-section blockquote.quote {
  position: relative;
}
.connecting-gray-text {
  background-color: #f6f6f6;
  padding: 3%;
}
/*
.connecting-testimonial-section blockquote.EN {
  border-top: 1px solid #fec671;
  border-bottom: 1px solid #fec671;
  flex-wrap: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
  margin: 0 35px;
}
*/
.connecting-text-container {
  margin: auto;
  border-radius: 30px;
  width: 90%;
  display: flex;
  background-color: #ffff;
  margin-top: 3%;
  -moz-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  -webkit-box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
  box-shadow: 5px 5px 5px rgba(68, 68, 68, 0.6);
}

.connecting-text-section {
  width: auto;
  max-width: 1500px;
  margin: 0 auto;
  padding: 3rem;
  align-items: center;
}
.connecting-text-section h2 {
  margin-bottom: 2rem;
}

.connecting-testimonial-section blockquote h2 {
  text-align: left;
  font-family: inherit;
  font-size: 1.375rem;
  margin-bottom: 0;
}
.connecting-testimonial-section blockquote p {
  line-height: 1.4;
  margin: 0;
  font-size: 1.375rem;
  text-align: left;
}
.connecting-testimonial-img {
  width: 106px;
  height: 106px;
  margin-right: 20px;
}
.connecting-icon-text {
  font-weight: 600;
}
.connecting-hr {
  border: 5px solid #fed834;
  margin-top: 260px;
  position: relative;
  width: 100%;
}
.connection-challenges-section {
  padding-bottom: 50px;
}
.connecting-section-block {
  padding: 0px;
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  grid-template-columns: 300px 1fr;
  max-width: 1100px;
  width: auto;
}

.connecting-block-img {
  border: 1px solid #000000;
  padding: 10px;
  border-radius: 30px;
  position: relative;
  margin-top: -150px;
}

.connecting-block-text {
  margin-top: 0px;
  margin-left: 50px;
}
.connecting-mobile-no-show {
  display: none;
}
.connecting-mobile-show {
  display: none;
}

@media (min-width: 600px) {
  .display-h1 {
    font-size: 3.5rem;
  }
  .connecting-banner-container,
  .connecting-banner-container-bottom {
    flex-direction: row;
  }
  .connecting-banner-container-bottom {
    width: 100%;
    max-width: 1200px;
    justify-content: space-between;
    padding: 65px 0 50px;
  }
  .connecting-banner-container-bottom > div {
    margin: 15px;
  }
  .connecting-banner-bottom {
    background-image: linear-gradient(0deg, #ffe6c0, #ffe6c0);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position-y: 70%;
    display: flex;
    justify-content: center;
  }
  .connecting-testimonial-section blockquote.EN {
    /* flex-direction: row; */
    padding: 10px 0;
  }
  .connecting-testimonial-section blockquote.EN:before {
    left: -32px;
  }
  .connecting-testimonial-section blockquote.EN:after {
    right: -32px;
  }
  .connecting-testimonial-section blockquote.EN p {
    text-align: left;
  }
}

@media (max-width: 900px) {
  .connecting-section-block {
    grid-template-columns: 1fr;
  }
  .connecting-section-block-text {
    margin-top: 0px;
    margin-left: 5px;
  }
  .connecting-mobile-show {
    display: block;
  }
}

@media (min-width: 900px) {
  .connecting-block-text-points {
    margin-top: 5%;
  }
  .connecting-block-text {
    margin-top: -220;
  }
  .connecting-banner {
    margin-bottom: 50px;
  }
  .connecting-mobile-no-show {
    display: block;
  }
  .connecting-banner-text {
    padding-right: 20px;
  }
  .connecting-banner h1 {
    /* font-size: 4rem; */
  }

  .connecting-banner-container-bottom {
    position: relative;
  }
}
@media (min-width: 1200px) {
  .connecting-block-text::after {
    top: calc(170%);
    right: 8%;
  }
  .connecting-block-text {
    margin-top: -200px;
  }
  .connecting-text-container {
    width: 55%;
  }
  .connecting-testimonial-section::after {
    right: 38%;
    transform: rotate(-40deg);
  }
}

@media (max-width: 500px) {
  .connecting-text-section {
    padding: 1.5rem;
  }
}
