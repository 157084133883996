[data-theme="blue"] {
  #supportTopics {
    // border-top: 14px solid var(--cerulean);
  }
  .fab-wrapper {
    .fab {
      background-color: var(--cg-blue);
      &:hover, &:active {
        filter: brightness(120%);
      }
    }
    .learning-guide-collapse-panel {
      background-color: var(--blue-diamond);
      border-color: var(--cg-blue);
      .learning-guide-menu {
        li:hover {
          border-color: rgba(0, 125, 163, 0.8)
        }
        li.current {
          border-color: var(--cg-blue);
        }
      }
    }
  }
}
[data-theme="azure"] {
  #supportTopics {
    // border-top: 14px solid var(--keppel);
  }
  .fab-wrapper {
    .fab {
      background-color: var(--cg-blue);
      &:hover, &:active {
        filter: brightness(120%);
      }
    }
    .learning-guide-collapse-panel {
      background-color: var(--azure);
      border-color: var(--sea-green);
      .learning-guide-menu {
        li:hover {
          border-color: rgba(36, 204, 168, 0.8)
        }
        li.current {
          border-color: var(--sea-green);
        }
      }
    }
  }
}
[data-theme="yellow"] {
  #supportTopics {
    // border-top: 14px solid var(--cg-blue);
  }
  .fab-wrapper {
    .fab {
      background-color: rgba(0, 125, 163, 0.8);
      &:hover, &:active {
        background-color: rgba(0, 125, 163, 1);
      }
    }
    .learning-guide-collapse-panel {
      background-color: var(--lt-yellow);
      border-color: var(--gold);
      .learning-guide-menu {
        li:hover {
          border-color: rgba(228, 137, 18, 0.8)
        }
        li.current {
          border-color: var(--gold);
        }
      }
    }
  }
}
[data-theme="cyan"] {
  #supportTopics {
    // border-top: 14px solid var(--deep-green);
  }
  .fab-wrapper {
    .fab {
      background-color: var(--deep-green);
      &:hover, &:active {
        background-color: var(--deep-green);
        filter: brightness(120%);
      }
    }
    .learning-guide-collapse-panel {
      background-color: var(--lt-cyan);
      border-color: var(--cg-blue);
      .learning-guide-menu {
        li:hover {
          border-color: rgba(0, 125, 163, 0.8)
        }
        li.current {
          border-color: var(--cg-blue);
        }
      }
    }
  }
}
[data-theme="amber"] {
  #supportTopics {
    // border-top: 14px solid #811722;
  }
  .fab-wrapper {
    .fab {
      color: #fff;
      background-color: var(--dark-teal);
      &:hover, &:active {
        filter: brightness(120%);
        text-decoration-color: var(--dark-charcoal);
      }
    }
    .learning-guide-collapse-panel {
      background-color: var(--lt-yellow);
      border-color: var(--topaz);
      .learning-guide-menu {
        li:hover {
          border-color: rgba(254, 216, 52, 0.8);
        }
        li.current {
          border-color: var(--topaz);
        }
      }
    }
  }
}
[data-theme="pink"] {
  #supportTopics {
    // border-top: 14px solid var(--maroon);
  }
  .fab-wrapper {
    .fab {
      background-color: var(--plum);
      &:hover, &:active {
        filter: brightness(120%);
      }
    }
    .learning-guide-collapse-panel {
      background-color: var(--pink);
      border-color: var(--maroon);
      .learning-guide-menu {
        li:hover {
          border-color: rgba(137, 9, 79, 0.7);
        }
        li.current {
          border-color: var(--maroon);
        }
      }
    }
  }
}